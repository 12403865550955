import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  useToast,
  HStack,
  CircularProgress,
} from "@chakra-ui/react";
import {
  MdOutlineImportExport,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../../utils/sortData";
import moment from "moment/moment";
import AgregarNroremito from "../../operaciones/components/curso/reporte/AgregarNroremito";
import AgregarRemito from "../../operaciones/components/curso/reporte/AgregarRemito";
import AgregarToneladas from "../../operaciones/components/curso/reporte/AgregarToneladas";
import { getReportesViajesFiltrados } from "../helpers/api.helper";

const DisenoTablaFiltrada = (props) => {
  const {
    user,
    initData,
    filterBy,
    padDestino,
    setLengthReportes,
    reportesViajes,
    setReportesViajes,
  } = props;
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [loadingII, setLoadingII] = useState(false);
  const toast = useToast();

  const handleColorEstado = (e) => {
    switch (e) {
      case "Finalizado":
        return "brand.verde";
      case "Caducado":
        return "brand.rojo";
      case "En curso":
        return "#fbb034";
      case "cancelado":
        return "brand.gris_medio";
      default:
        return "-";
    }
  };

  const handleTextoEstado = (e) => {
    switch (e) {
      case "En curso":
        return "En curso";
      case "Finalizado":
        return "Finalizado";
      case "cancelado":
        return "Cancelado";
      case "Caducado":
        return "Caducado";
      default:
        return "-";
    }
  };
  const handleFilter = async () => {
    setLoadingII(true);
    try {
      let res;
      let filtroActivo = {};

      if (filterBy.dominio.length > 0) {
        filtroActivo.patentes = filterBy.dominio;
      }
      if (filterBy.interno.length > 0) {
        filtroActivo.interno = filterBy.interno;
      }
      if (filterBy.transportista.length > 0) {
        filtroActivo.transportista = filterBy.transportista;
      }
      if (filterBy.status2RV.length > 0) {
        filtroActivo.statusRV2 = filterBy.status2RV;
      }
      if (filterBy.statusRV.length > 0) {
        filtroActivo.statusRV = filterBy.statusRV;
      }
      if (padDestino) {
        filtroActivo.pad = [padDestino];
      } else if (filterBy.padDes.length > 0) {
        filtroActivo.pad = filterBy.padDes;
      }

      if (filterBy.fin) {
        filtroActivo.fin = moment(filterBy.fin).format("YYYY-MM-DD");
      }
      if (filterBy.inicio) {
        filtroActivo.inicio = moment(filterBy.inicio).format("YYYY-MM-DD");
      }

      res = await getReportesViajesFiltrados({
        ...filtroActivo,
      });

      if (res.status === 200) {
        setReportesViajes(res.data);
        setLengthReportes(res.data.totalData);
      } else if (res.status === 204) {
        toast({
          status: "success",
          description: "Cargados correctamente",
          isClosable: true,
          duration: 2000,
        });
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los reportes",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al cargar los reportes:", error);
      toast({
        title: "Error",
        description: "Error al cargar los reportes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingII(false); // Establecer el estado de carga a falso, independientemente del resultado
    }
  };

  const handleLoading = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    handleFilter();
  }, [filterBy]);

  return (
    <TableContainer h={"lg"} overflowY={"scroll"} id="TBody">
      <Table size="sm" variant="simple">
        <Thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#F3F6F9",
            opacity: 1,
          }}
        >
          <Tr>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "idReporteViaje",
                    reportesViajes
                  );
                  setSortField("idReporteViaje");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                ID viaje
                {sortDirection === "asc" && sortField === "idReporteViaje" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "idReporteViaje" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon1").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon1").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "statusRV",
                    reportesViajes
                  );
                  setSortField("statusRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Estado
                {sortDirection === "asc" && sortField === "statusRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "statusRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon1" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon2").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon2").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "status2RV",
                    reportesViajes
                  );
                  setSortField("status2RV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Locación
                {sortDirection === "asc" && sortField === "status2RV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "status2RV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon2" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon3").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon3").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "padDestino",
                    reportesViajes
                  );
                  setSortField("padDestino");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                PAD destino
                {sortDirection === "asc" && sortField === "padDestino" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "padDestino" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon3" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon4").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon4").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "transporteRV",
                    reportesViajes
                  );
                  setSortField("transporteRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Transporte
                {sortDirection === "asc" && sortField === "transporteRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "transporteRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon4" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon5").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon5").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "camionRV",
                    reportesViajes
                  );
                  setSortField("camionRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Camion
                {sortDirection === "asc" && sortField === "camionRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "camionRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon5" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon23").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon23").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "nombreCamion",
                    reportesViajes
                  );
                  setSortField("nombreCamion");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Interno
                {sortDirection === "asc" && sortField === "nombreCamion" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "nombreCamion" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon23" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon6").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon6").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "sandCube1",
                    reportesViajes
                  );
                  setSortField("sandCube1");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                SCs asociaciados
                {sortDirection === "asc" && sortField === "sandCube1" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "sandCube1" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon6" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon7").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon7").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "sandCube2",
                    reportesViajes
                  );
                  setSortField("sandCube2");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                SCs asociaciados
                {sortDirection === "asc" && sortField === "sandCube2" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "sandCube2" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon7" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon8").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon8").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "plantaCarga",
                    reportesViajes
                  );
                  setSortField("plantaCarga");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Planta Carga
                {sortDirection === "asc" && sortField === "plantaCarga" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "plantaCarga" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon8" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon9").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon9").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "tsPozoOut",
                    reportesViajes
                  );
                  setSortField("tsPozoOut");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                TS Pozo out
                {sortDirection === "asc" && sortField === "tsPozoOut" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "tsPozoOut" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon9" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon10").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon10").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "tsPlantaIn",
                    reportesViajes
                  );
                  setSortField("tsPlantaIn");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                TS Planta in
                {sortDirection === "asc" && sortField === "tsPlantaIn" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "tsPlantaIn" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon10" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon11").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon11").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "tsPlantaOut",
                    reportesViajes
                  );
                  setSortField("tsPlantaOut");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                TS Planta out
                {sortDirection === "asc" && sortField === "tsPlantaOut" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "tsPlantaOut" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon11" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>

            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon12").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon12").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "tsPozoIn",
                    reportesViajes
                  );
                  setSortField("tsPozoIn");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                TS Pozo in
                {sortDirection === "asc" && sortField === "tsPozoIn" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "tsPozoIn" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon12" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon13").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon13").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "eta",
                    reportesViajes
                  );
                  setSortField("eta");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                ETA
                {sortDirection === "asc" && sortField === "eta" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "eta" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon13" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon14").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon14").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "standByPlanta",
                    reportesViajes
                  );
                  setSortField("standByPlanta");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Standby en Planta (mins.)
                {sortDirection === "asc" && sortField === "standByPlanta" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "standByPlanta" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon14" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon15").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon15").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "standByPozo",
                    reportesViajes
                  );
                  setSortField("standByPozo");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Standby en Pozo (mins.)
                {sortDirection === "asc" && sortField === "standByPozo" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "standByPozo" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon15" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon16").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon16").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "tiempoVuelta",
                    reportesViajes
                  );
                  setSortField("tiempoVuelta");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Tiempo Vuelta (mins.)
                {sortDirection === "asc" && sortField === "tiempoVuelta" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "tiempoVuelta" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon16" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon17").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon17").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "kms",
                    reportesViajes
                  );
                  setSortField("kms");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                kms
                {sortDirection === "asc" && sortField === "kms" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "kms" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon17" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon18").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon18").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "fechaRV",
                    reportesViajes
                  );
                  setSortField("fechaRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Fecha reporte
                {sortDirection === "asc" && sortField === "fechaRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "fechaRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon18" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon19").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon19").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "cargaTotal",
                    reportesViajes
                  );
                  setSortField("cargaTotal");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                {" "}
                Carga Total
                {sortDirection === "asc" && sortField === "cargaTotal" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "cargaTotal" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon19" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon20").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon20").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "demoras",
                    reportesViajes
                  );
                  setSortField("demoras");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                {" "}
                Demoras (mins.)
                {sortDirection === "asc" && sortField === "demoras" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "demoras" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon20" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>

            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon21").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon21").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "numRemitoRV",
                    reportesViajes
                  );
                  setSortField("numRemitoRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Nro. de Remito
                {sortDirection === "asc" && sortField === "numRemitoRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "numRemitoRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon21" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
            <Th>Imagen Remito</Th>
            <Th
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() =>
                (document.querySelector(".hover-icon22").style.display =
                  "inline-block")
              }
              onMouseLeave={() =>
                (document.querySelector(".hover-icon22").style.display = "none")
              }
            >
              <span
                className="sortable"
                onClick={() => {
                  const { sortedData, direction } = sortData(
                    "ToneladasRV",
                    reportesViajes
                  );
                  setSortField("ToneladasRV");
                  setSortDirection(direction);
                  setReportesViajes(sortedData);
                }}
                _hover={<Icon as={MdOutlineImportExport} />}
              >
                Toneladas
                {sortDirection === "asc" && sortField === "ToneladasRV" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "des" && sortField === "ToneladasRV" && (
                  <ArrowDownIcon />
                )}
              </span>
              <span className="hover-icon22" style={{ display: "none" }}>
                <MdOutlineImportExport />
              </span>
            </Th>
          </Tr>
        </Thead>
        {loadingII && <Tbody>{handleLoading()}</Tbody>}

        {!loadingII && reportesViajes.length > 0 && (
          <Tbody>
            {reportesViajes.map((reporte) => (
              <Tr key={reporte.idReporteViaje}>
                <Td>{reporte.idReporteViaje}</Td>
                <Td color={handleColorEstado(reporte.statusRV)}>
                  {handleTextoEstado(reporte.statusRV)}
                </Td>
                <Td>{reporte.status2RV}</Td>
                <Td>{reporte.padDestino}</Td>

                <Td>{reporte.transporteRV}</Td>
                <Td>{reporte.camionRV}</Td>
                <Td>{reporte.nombreCamion}</Td>

                <Td>{reporte.sandCube1}</Td>
                <Td>{reporte.sandCube2}</Td>
                <Td>{reporte.plantaCarga}</Td>
                <Td>
                  {reporte.tsPozoOut &&
                    moment.utc(reporte.tsPozoOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaIn &&
                    moment.utc(reporte.tsPlantaIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaOut &&
                    moment.utc(reporte.tsPlantaOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPozoIn &&
                    moment.utc(reporte.tsPozoIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.eta &&
                    moment.utc(reporte.eta).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>{reporte.standByPlanta}</Td>
                <Td>{reporte.standByPozo}</Td>
                <Td>{reporte.tiempoVuelta}</Td>
                <Td>{reporte.kms}</Td>
                <Td>
                  {reporte.fechaRV &&
                    moment.utc(reporte.fechaRV).format("DD/MM/YYYY")}
                </Td>
                <Td>{reporte.cargaTotal}</Td>
                <Td>{reporte.demoras}</Td>
                {!reporte.numRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarNroremito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td>{reporte.numRemitoRV || "-"}</Td>
                )}
                {!reporte.imgRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarRemito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : reporte.imgRemitoRV ? (
                  <Td>
                    <Icon
                      as={MdOutlineCheckCircleOutline}
                      color="white"
                      bg="green"
                      borderRadius="50%"
                    />
                  </Td>
                ) : (
                  <Td>-</Td>
                )}
                {!reporte.ToneladasRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarToneladas
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td>{reporte.ToneladasRV || "-"}</Td>
                )}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};

export default DisenoTablaFiltrada;
