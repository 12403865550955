import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ColorModeScript } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Global } from "@emotion/react";
import { LoadScript } from "@react-google-maps/api";
import "material-icons/iconfont/material-icons.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/auth/helpers/api.helper";
import * as Sentry from "@sentry/react";

const location = window.location.hostname;

location === "http://localhost:3000/" &&
  Sentry.init({
    dsn: "https://ab919e9aaeb291ca237b3680ed88c956@o4506575645048832.ingest.sentry.io/4506575647211520",
    environment:
      location === "https://qasandlog.grupomurchison.com/"
        ? "testing"
        : "production",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));

const libs = ["visualization", "geometry", "drawing", "places"];

const CustomScrollbarStyles = () => (
  <Global
    styles={`
      ::-webkit-scrollbar {
        width: 8px;
        height: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
    `}
  />
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <LoadScript
        libraries={libs}
        googleMapsApiKey={process.env.REACT_APP_API_KEY}
      >
        <ColorModeScript />
        <CustomScrollbarStyles />
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </LoadScript>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
