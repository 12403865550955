import React, { useState } from "react";
import {
  Center,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  Divider,
  HStack,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import Fondo from "../../../assets/fondo.png";
import { useNavigate, useParams } from "react-router-dom";
import { changePassword } from "../helpers/api.helper";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const PantallaRecuperacion = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const toast = useToast();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const sendData = async () => {
    setLoading(true);
    if (!confirmPassword || !password) {
      return toast({
        title: "Error",
        description: "Parametros insuficientes",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    let res;
    if (password === confirmPassword) {
      res = await changePassword({
        newPassword: password,
        token: token,
      });
    }
    if (res.status === 200) {
      return toast({
        title: "Confirmacion",
        description: "Contraseña cambiada correctamente",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  return (
    <Stack
      w="100%"
      h="100vh"
      bgImage={Fondo}
      objectFit="cover"
      bgSize="100%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Center h="100%" bg="rgba(255, 255, 255, .5)">
        <Card w="md" h="lg">
          <CardHeader>Logo img</CardHeader>
          <CardBody>
            <FormControl>
              <FormLabel>Escriba su nueva contraseña</FormLabel>
              <InputGroup size="md">
                <Input
                  border="1px solid black"
                  value={password}
                  name="password"
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  type={show ? "text" : "password"}
                  _placeholder={{ color: "black.300" }}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    pl={2}
                    size="sm"
                    variant="unstyled"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <br />
            <FormControl>
              <FormLabel>Confirme la contraseña</FormLabel>
              <Input
                value={confirmPassword}
                name="confirmPassword"
                placeholder="Contraseña"
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={loading}
                _placeholder={{ color: "black.300" }}
              />
            </FormControl>
            <HStack w="100%" py={10} justifyContent="center">
              <Button
                bg="brand.naranja"
                color="white"
                fontWeight="medium"
                isDisabled={loading}
                letterSpacing="1px"
                onClick={() => {
                  sendData();
                  navigate("/login");
                }}
              >
                Confirmar
              </Button>
            </HStack>
            <Divider />
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      </Center>
    </Stack>
  );
};

export default PantallaRecuperacion;
