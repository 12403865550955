import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
//import io from "socket.io-client ";

//const socket = io("");

const Alertas = () => {
  const [data, setData] = useState();
  const toast = useToast();
  let camion = { _id: 1, nombreCamion: "Int", dominio: "AAA123" };

  const handleAlerta = async () => {
    let res;

    if (res.status === 200) {
      setData(res.data);
    }
  };

  const handleTotast = () => {
    return toast({
      title: "¡Alerta!",
      description: `El camion: ${camion.dominio}: ${handleAlerta()}`,
      status: "warning", // Puedes usar 'success', 'error', 'warning' o 'info'
      duration: 3000, // Duración en milisegundos
      isClosable: true, // Puede cerrarse haciendo clic en la 'X'
    });
  };

  useEffect(() => {
    /*  socket.on("dataChanged", (newData) => {
      handleAlerta();
    }); */

    handleAlerta();

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      //socket.off("dataChanged");
    };
  }, []);

  return { handleTotast };
};

export default Alertas;
