import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineAdd } from "react-icons/md";
import { editarPlanificacion } from "../../helpers/api.helper";

const AgregarTnsNominales = (props) => {
  const { ejemplo, initData } = props;
  const [tnsNominales, setTnsNominales] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleAgregar = async () => {
    let res;

    res = await editarPlanificacion({
      id: ejemplo.idPlanificacion,
      tnsNominales: tnsNominales,
    });

    if (res.status === 200) {
      initData();
      onClose();
      toast({
        title: "Exito",
        description: "Toneladas nominales agregadas",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar las toneladas",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Button
        variant="link"
        leftIcon={<MdOutlineAdd color="#FF8200" />}
        onClick={onOpen}
        size="sm"
      >
        Agregar
      </Button>
      <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader placeSelf="center" fontWeight="bold">
            Toneladas Nominales
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <Input
              placeholder="Tns. nominales"
              borderRadius="20px"
              onChange={(e) => setTnsNominales(e.target.value)}
              type="number"
            />
          </ModalBody>

          <ModalFooter placeContent="center">
            <Button
              bg="brand.naranja"
              fontSize="14px"
              color="white"
              onClick={() => handleAgregar()}
            >
              Cargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AgregarTnsNominales;
