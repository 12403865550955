import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  HStack,
  VStack,
  useToast,
  Card,
  CardBody,
  CardFooter,
  Button,
  Select,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { MdNavigateNext, MdFileDownloadDone } from "react-icons/md";
import { FieldArray } from "formik";
import {
  crearPlanificacion,
  editarPlanificacion1,
  getPlanificaciones,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const PrimerPaso = (props) => {
  const {
    activeStep,
    setActiveStep,
    type,
    editable,
    handleId,
    planificaciones,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    clientes,
    expanded,
    setPlan,
    formValues,
  } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const toast = useToast();
  const navigate = useNavigate();

  function formatDate(date, fecha) {
    const year = new Date(date).getFullYear();
    const month = String(new Date(date).getMonth() + 1).padStart(2, "0");
    const day = String(new Date(date).getDate()).padStart(2, "0");

    if (fecha === "FI") {
      return `${year}-${month}-${day}T00:00:00.0000`;
    } else if (fecha === "FF") {
      return `${year}-${month}-${day}T23:59:59.0000`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }

  const hadnleOperadora = (value) => {
    setFieldValue("operadora", value.nombreCliente);
  };

  const handleSubmit = async (values, event) => {
    setLoading(true);
    if (!values.idPadlocacion) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "ID de pad/locacion requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.fechaInicio) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha de inicio de operacion requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.fechaFinal) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha de fin de operacion requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.cliente) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Cliente requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);

    let plan = [];
    let resolve = await getPlanificaciones();

    if (resolve.status === 200) {
      plan = resolve.data;
    } else if (resolve.status === 204) {
      plan = [];
    }

    let data =
      type === "editar" || type === "reActivar"
        ? {
            idPadlocacion: values.idPadlocacion,
            idSET: values.idSET,
            fechaInicio: formatDate(values.fechaInicio, "FI"),
            fechaFinal: formatDate(values.fechaFinal, "FF"),
            fechaDTM: formatDate(values.fechaDTM, "DMT"),
            cliente: values.cliente,
            operadora: values.operadora,
            companiaServicio: values.otro
              ? values.otro
              : values.companiaServicio,
            descripcionAreaHidrocarburifera: text,
            nombreAreaHidrocarburifera: values.nombreAreaHidrocarburifera,
            direccion: values.direccion,
            ciudad: values.ciudad,
            provincia: values.provincia,
            estado: formValues.estado,
            id: formValues.idPlanificacion,
            desMontTransp: false,
          }
        : {
            idPadlocacion: values.idPadlocacion,
            idSET: values.idSET,
            fechaInicio: formatDate(values.fechaInicio, "FI"),
            fechaFinal: formatDate(values.fechaFinal, "FF"),
            fechaDTM: formatDate(values.fechaDTM, "DMT"),
            cliente: values.cliente[0].idCliente,
            operadora: values.operadora,
            companiaServicio: values.otro
              ? values.otro
              : values.companiaServicio,
            descripcionAreaHidrocarburifera: text,
            nombreAreaHidrocarburifera: values.nombreAreaHidrocarburifera,
            direccion: values.direccion,
            ciudad: values.ciudad,
            provincia: values.provincia,
            estado: "proyectado",
            desMontTransp: false,
          };
    let res;

    if (event === "continuar") {
      if (type === "editar" || type === "reActivar") {
        res = await editarPlanificacion1(data);
      } else {
        if (
          plan.length > 0 &&
          plan.some((e) => e.idPadlocacion === data.idPadlocacion)
        ) {
          let a = plan.filter((e) => e.idPadlocacion === data.idPadlocacion);

          res = await editarPlanificacion1({
            ...data,
            id: a[0].idPlanificacion,
          });
        } else {
          res = await crearPlanificacion(data);
        }
      }

      if (res.status === 200) {
        toast({
          status: "success",
          isClosable: true,
          title: `Primer paso ${
            type === "editar" || type === "reActivar" ? "editado" : "creado"
          } correctamente`,
          duration: 3000,
        });
      } else {
        setLoading(false);
        return toast({
          status: "error",
          isClosable: true,
          title: `Error al ${
            type === "editar" || type === "reActivar" ? "editar" : "crear"
          } el primer paso`,
          description: res.message,
        });
      }
      if (type === "crear") {
        handleId();
      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      if (type === "editar" || type === "reActivar") {
        res = await editarPlanificacion1({
          ...data,
          id: editable.idPlanificacion,
        });
      } else {
        res = await crearPlanificacion(data);
      }

      if (res.status === 200) {
        toast({
          status: "success",
          isClosable: true,
          title: `Primer paso ${
            type === "editar" || type === "reActivar" ? "editado" : "creado"
          } correctamente`,
          duration: 3000,
        });
      } else {
        setLoading(false);
        return toast({
          status: "error",
          isClosable: true,
          title: `Error al ${
            type === "editar" || type === "reActivar" ? "editar" : "crear"
          } el primer paso`,
          description: res.message,
        });
      }

      navigate(-1);
    }
    setPlan(data);
    setLoading(false);
  };

  const handleCliente = (elemento) => {
    let cliente = clientes.filter((e) => e.idCliente === elemento);

    return cliente[0].nombreCliente;
  };

  const handleCaracteres = (event) => {
    const { value } = event.target;
    setText(value);
    setFieldValue("descripcionAreaHidrocarburifera", value);
  };
  const charCount = text.length;

  const verificarFechaInicio = (fecha, setFieldValue, idSET) => {
    let filtrado = planificaciones.filter((e) => e.idSET === idSET);
    filtrado = filtrado.filter(
      (e) => e.estado === "activo" || e.estado === "proyectado"
    );
    filtrado = filtrado.map((e) => {
      return { fechaInicio: e.fechaInicio, fechaFinal: e.fechaFinal };
    });
    const fechaObj = moment(fecha).format("DD-MM-YYYY 00:00");

    for (let i = 0; i < filtrado.length; i++) {
      const fi = filtrado[i].fechaInicio;
      const ff = filtrado[i].fechaFinal;

      if (fi < fechaObj && ff > fechaObj) {
        toast({
          title: "Atención",
          description: "Esa fecha se superpone con otra planificacion",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        break;
      }
    }

    setFieldValue("fechaInicio", fecha);
  };

  const verificarFechaFin = (fecha, setFieldValue, idSET) => {
    let filtrado = planificaciones.filter((e) => e.idSET === idSET);
    filtrado = filtrado.filter(
      (e) => e.estado === "activo" || e.estado === "proyectado"
    );
    filtrado = filtrado.map((e) => {
      return { fechaInicio: e.fechaInicio, fechaFinal: e.fechaFinal };
    });
    const fechaObj = moment(fecha).format("DD-MM-YYYY 00:00");

    for (let i = 0; i < filtrado.length; i++) {
      const fi = filtrado[i].fechaInicio;
      const ff = filtrado[i].fechaFinal;

      if (fi < fechaObj && ff > fechaObj) {
        toast({
          title: "Atención",
          description: "Esa fecha se superpone con otra planificacion",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        break;
      }
    }

    setFieldValue("fechaFinal", fecha);
  };

  return (
    <Stack px={3}>
      <HStack
        pb={1}
        w="100%"
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">ID Pad / Locación</FormLabel>
          <Input
            w="2xs"
            type="text"
            placeholder="Nombre"
            name="idPadlocacion"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.idPadlocacion}
            readOnly={type === "editar" ? true : false}
          />
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold" letterSpacing={1}>
            Identificacion del SET
          </FormLabel>
          <RadioGroup
            name="idSET"
            value={values.idSET}
            defaultValue={type === "editar" ? editable.idSET : ""}
          >
            <Stack direction="row" spacing={16}>
              <Radio value="set1" onChange={handleChange} onBlur={handleBlur}>
                SET 1
              </Radio>
              <Radio value="set2" onChange={handleChange} onBlur={handleBlur}>
                SET 2
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl w="xs"></FormControl>
      </HStack>
      <HStack
        pb={1}
        spacing={20}
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha Inicio</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaInicio"
            onChange={(e) =>
              verificarFechaInicio(e.target.value, setFieldValue, values.idSET)
            }
            onBlur={handleBlur}
            value={values.fechaInicio}
            disabled={!values.idSET ? true : false}
          />
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha de Final</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaFinal"
            onChange={(e) =>
              verificarFechaFin(e.target.value, setFieldValue, values.idSET)
            }
            min={values.fechaInicio}
            onBlur={handleBlur}
            value={values.fechaFinal}
            disabled={!values.idSET ? true : false}
          />
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha DTM</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaDTM"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaDTM}
          />
        </FormControl>
      </HStack>
      <HStack
        pb={1}
        spacing={20}
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        {(type === "editar" || type === "reActivar") && (
          <>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Cliente</FormLabel>
              <Input
                w="2xs"
                type="text"
                name="cliente"
                value={handleCliente(values.cliente)}
                readOnly
              />
            </FormControl>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Operadora</FormLabel>
              <Input
                w="2xs"
                type="text"
                name="operadora"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.operadora}
                readOnly
              />
            </FormControl>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Compañía de servicio</FormLabel>
              <Select
                w="2xs"
                type="text"
                placeholder="Nombre"
                name="companiaServicio"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companiaServicio}
              >
                <option value="Schlumberger">Schlumberger</option>
                <option value="Halliburton">Halliburton</option>
                <option value="Weatherford">Weatherford</option>
                <option value="Otro">Otro</option>
              </Select>
            </FormControl>
            {values.companiaServicio === "Otro" && (
              <FormControl w="xs">
                <FormLabel fontWeight="semibold"></FormLabel>
                <Input
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="otro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formValues.otro}
                />
              </FormControl>
            )}
          </>
        )}
        {type === "crear" && (
          <FormControl w="xs" isRequired>
            <FormLabel fontWeight="semibold">Cliente</FormLabel>
            <FieldArray name="cliente">
              {({ push, remove }) => (
                <Select
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="cliente"
                  onChange={(e) => {
                    remove(values.cliente[0]);
                    push(JSON.parse(e.target.value));
                    hadnleOperadora(JSON.parse(e.target.value));
                  }}
                  onBlur={handleBlur}
                >
                  {clientes.map((cliente) => {
                    return (
                      <option
                        key={cliente.idCliente}
                        value={JSON.stringify(cliente)}
                      >
                        {cliente.nombreCliente}
                      </option>
                    );
                  })}
                </Select>
              )}
            </FieldArray>
          </FormControl>
        )}

        {type === "crear" && values.cliente.length > 0 && (
          <>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Operadora</FormLabel>
              {values.cliente[0].tipoCliente === "operadora" && (
                <Input
                  w="2xs"
                  type="text"
                  name="operadora"
                  onBlur={handleBlur}
                  value={values.cliente[0].nombreCliente}
                  readOnly
                />
              )}
              {values.cliente[0].tipoCliente === "companiaServicio" && (
                <Input
                  w="2xs"
                  type="text"
                  name="operadora"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.operadora}
                />
              )}
            </FormControl>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Compañía de servicio</FormLabel>
              {values.cliente[0].tipoCliente === "companiaServicio" && (
                <Input
                  w="2xs"
                  type="text"
                  name="companiaServicio"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cliente[0].nombreCliente}
                  readOnly
                />
              )}
              {values.cliente[0].tipoCliente === "operadora" && (
                <Select
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="companiaServicio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companiaServicio}
                >
                  <option value="Schlumberger">Schlumberger</option>
                  <option value="Halliburton">Halliburton</option>
                  <option value="Weatherford">Weatherford</option>
                  <option value="Otro">Otro</option>
                </Select>
              )}
            </FormControl>
            {values.companiaServicio === "Otro" && (
              <FormControl w="xs">
                <FormLabel fontWeight="semibold"></FormLabel>
                <Input
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="otro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otro}
                />
              </FormControl>
            )}
          </>
        )}
      </HStack>
      <HStack
        w="100%"
        justifyContent={!expanded ? "space-between" : "unset"}
        pb={1}
        spacing={20}
      >
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Area hidrocarburífera</FormLabel>
          <Stack>
            <Input
              w="2xs"
              type="text"
              placeholder="Nombre"
              name="nombreAreaHidrocarburifera"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.nombreAreaHidrocarburifera}
            />
            <Card w="2xs" variant="unstyled" bg="brand.fondos_secundarios">
              <CardBody>
                <Textarea
                  w="2xs"
                  placeholder="Descripcion"
                  name="descripcionAreaHidrocarburifera"
                  onChange={handleCaracteres}
                  onBlur={handleBlur}
                  value={values.descripcionAreaHidrocarburifera}
                  maxLength="250"
                  resize="none"
                />
              </CardBody>
              <CardFooter placeSelf="end" color="brand.gris_medio">
                {charCount}/250
              </CardFooter>
            </Card>
          </Stack>
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Localización</FormLabel>
          <Stack>
            <Input
              w="2xs"
              type="text"
              placeholder="Dirección"
              name="direccion"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.direccion}
            />
            <Input
              w="2xs"
              type="text"
              placeholder="Ciudad"
              name="ciudad"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ciudad}
            />
            <Input
              w="2xs"
              type="text"
              placeholder="Provincia"
              name="provincia"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.provincia}
            />
          </Stack>
        </FormControl>
        <VStack w="xs" placeSelf="end">
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            onClick={() => handleSubmit(values, "continuar")}
            variant="solid"
            form="FormularioPlanificacion"
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
          <Button
            color="brand.naranja"
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            variant="outline"
            leftIcon={<MdFileDownloadDone fontSize="20px" />}
            textColor="brand.naranja"
            form="FormularioPlanificacion"
            onClick={() => handleSubmit(values, "finalizar")}
            isDisabled={loading}
            _hover={{ bg: "brand.naranja", color: "white" }}
          >
            Finalizar
          </Button>
        </VStack>
      </HStack>
    </Stack>
  );
};

export default PrimerPaso;
