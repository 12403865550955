import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Stack,
} from "@chakra-ui/react";

const FormModalUsuarios = ({
  isOpen,
  onClose,
  usuarios,
  usuariosSeleccionados,
  handleCheckboxChange,
  closeModal,
  setIsModalOpen,
  selectedItem,
}) => {
  const handleTitulo = (rol) => {
    const rolesTitulos = {
      admin: "Admin",
      cliente: "Cliente",
      usuarioBrent: "Usuario BRENT",
      adminOL: "Admin Operaciones y Logística",
      coorOp: "Coordinador de Operaciones",
      operaciones: "userOperaciones",
      userOperaciones: "Usuario de Operaciones",
      userFacturacion: "Usuario de Facturación",
      userHS: "Usuario de HyS",
      gerencia: "Gerencia",
      userMantenimiento: "Usuario de Mantenimiento",
    };

    return rolesTitulos[rol] || "Rol no reconocido";
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> {handleTitulo(selectedItem.rolUsuario)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3} px={5}>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Nombre y Apellido</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usuarios
                    .filter(
                      (usuario) => usuario.idRol === selectedItem.rolUsuario
                    )
                    .map((usuario) => (
                      <Tr key={usuario.idUsuario}>
                        <Td>
                          <Checkbox
                            isChecked={usuariosSeleccionados.some(
                              (u) => u.idUsuario === usuario.idUsuario
                            )}
                            onChange={() => handleCheckboxChange(usuario)}
                          />
                        </Td>
                        <Td>
                          {usuario.nombre}{" "}
                          {usuario.apellido !== "_undefined"
                            ? usuario.apellido
                            : ""}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal}>
            Close
          </Button>
          <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
            Listo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormModalUsuarios;
