import {
  Button,
  HStack,
  Input,
  Text,
  Box,
  VStack,
  Heading,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdNavigateBefore } from "react-icons/md";
import { recuperar } from "../helpers/api.helper";
import Fondo from "../../../assets/fondo.png";

const RecuperarDatos = () => {
  const [email, setEmail] = useState();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePassword = async () => {
    setLoading(true);
    const res = await recuperar({ email: email });

    if (res.status === 200) {
      return toast({
        description: "Correo Enviado",
        title: "Enviado",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
    }

    navigate(-1);
    setLoading(false);
  };

  return (
    <HStack
      w="100%"
      h="100vh"
      justifyContent='"center"'
      bgImage={Fondo}
      objectFit="contain"
      bgSize="100%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <VStack
        bg="rgba(255, 255, 255, 0.8)"
        boxSize="lg"
        mx="auto"
        py={3}
        spacing={5}
      >
        <Button
          leftIcon={<MdNavigateBefore />}
          position="fixed"
          top={5}
          right={10}
          variant="link"
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>

        <Heading fontWeight="medium" color="brand.azul">
          ¿Recordar Contraseña?
        </Heading>
        <Box px={5}>
          <Text>
            Por favor, introduzca la dirección de correo electrónico que utilizó
            para registrarse. Recibirá un enlace temporal para restablecer su
            contraseña.
          </Text>
          <br />
          <Text color="brand.azul">Contraseña</Text>
          <br />
          <Box>
            <Input
              placeholder="Ingrese el email"
              border="1px solid black"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <br />
          <Button
            w="100%"
            bg="brand.naranja"
            color="brand.fondo"
            isDisabled={loading}
            onClick={handlePassword}
          >
            ¡Enviar!
          </Button>
        </Box>
      </VStack>
    </HStack>
  );
};

export default RecuperarDatos;
