import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Heading,
  HStack,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Select,
  Input,
  Checkbox,
  CircularProgress,
} from "@chakra-ui/react";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { MdFilterList, MdRefresh } from "react-icons/md";
import EliminarGeocerca from "./components/EliminarGeocerca";
import { getGeocercas } from "./helpers/api.helper";
import { getClientes } from "../inventario/helpers/api.helper";
import { getCamiones } from "../operaciones/helpers/api.helper";
import { getPlantas } from "../inventario/helpers/api.helper";
import { getPlanificaciones } from "../operaciones/helpers/api.helper";
import CrearGeocerca from "./components/CrearGeocerca";
import CambionEnViaje from "../../assets/iconos/construccion.png";
import EditarGeocerca from "./components/EditarGeocerca";

const Geocercas = () => {
  const [map, setMap] = useState(null);

  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [verCamiones, setVerCamiones] = useState(false);
  const [camiones, setCamiones] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [planificaciones, setPlanificaciones] = useState([]);
  const [center, setCenter] = useState({
    lat: -38.33595844666532,
    lng: -68.79461933603682,
  });
  const [filterOn, setFilterOn] = useState(false);
  const [filterBy, setFilterBy] = useState({
    nombreGeo: "",
    tipoGeo: "",
    cliente: "",
  });
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedCoors, setSelectedCoors] = useState([]);
  const [geocercas, setGeocercas] = useState([]);
  const [seleccionado, setSeleccionado] = useState([]);
  const mapRef = useRef();

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: "#FF0000",
    fillOpacity: 0.5,
  };

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getGeocercas();

    if (res.status === 200) {
      setGeocercas(res.data);
    }

    res = await getClientes();

    if (res.status === 200) {
      setClientes(res.data);
    }
    res = await getCamiones();

    if (res.status === 200) {
      setCamiones(res.data);
    }

    res = await getPlantas();

    if (res.status === 200) {
      setPlantas(res.data);
    }

    res = await getPlanificaciones();

    if (res.status === 200) {
      setPlanificaciones(res.data);
    }

    setLoading(false);
  };

  const toggleCheckbox = (item) => {
    const index = selectedObjects.findIndex(
      (selectedItem) => selectedItem.idGeocerca === item.idGeocerca
    );
    if (index === -1) {
      setSelectedObjects([...selectedObjects, item]);
      setSelectedCoors([
        ...selectedCoors,
        { lat: item.latitudCentro, lng: item.longitudCentro },
      ]);
    } else {
      const updatedItems = [...selectedObjects];
      updatedItems.splice(index, 1);
      setSelectedObjects(updatedItems);
      const updatedCoors = selectedCoors.filter(
        (coor) =>
          coor.lat !== item.latitudCentro || coor.lng !== item.longitudCentro
      );
      setSelectedCoors(updatedCoors);
    }
  };

  const showGeocercas = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return geocercas;
    }
    return geocercas.filter((geocerca) => {
      const { nombreGeo, tipoGeo } = filterBy;

      return (
        (!nombreGeo || geocerca.nombreGeo.toLowerCase().includes(nombreGeo)) &&
        (!tipoGeo || geocerca.tipoGeo === tipoGeo)
      );
    });
  };
  const cleanFilter = () => {
    setFilterBy({
      nombreGeo: "",
      tipoGeo: "",
    });
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (map && selectedCoors.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      selectedCoors.forEach((object) => {
        bounds.extend(new window.google.maps.LatLng(object.lat, object.lng));
      });

      map.fitBounds(bounds);
    } else {
      setMap(map);
    }
  }, [map, selectedCoors]);

  const handleTipoGeo = (geo) => {
    switch (geo) {
      case "Base":
        return "Base";
      case "PAD":
        return "PAD";
      case "Planta":
        return "Planta";
      case "Ref":
        return "Ref";
      case "baseTransportista":
        return "Base Transportista";
      case "estacionServicio":
        return "Estación Servicio";
      case "barrera":
        return "Barrera";
      case "cercaniaPlanta":
        return "Cercanía planta";

      default:
        return "-";
    }
  };

  return (
    <Stack w={"100%"}>
      <HStack justifyContent={"space-between"} px={7}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Geocercas
        </Heading>
        <HStack spacing={5}>
          {filterOn && (
            <HStack
              h={filterOn ? "auto" : "none"}
              transitionDuration="2s"
              px={3}
            >
              <Select
                placeholder="Por Tipo"
                size="sm"
                w={150}
                onChange={(e) => {
                  setFilterBy((prevstate) => ({
                    ...prevstate,
                    tipoGeo: e.target.value,
                  }));
                  showGeocercas();
                }}
                value={filterBy.tipoGeo}
              >
                <option value="Base">Base</option>
                <option value="PAD">PAD</option>
                <option value="Planta">Planta</option>
                <option value="Ref">Punto de referencia</option>
              </Select>
              <Select
                placeholder="Por Cliente"
                size="sm"
                w={150}
                onChange={(e) => {
                  setFilterBy((prevstate) => ({
                    ...prevstate,
                    cliente: e.target.value,
                  }));
                  showGeocercas();
                }}
                value={filterBy.cliente}
              >
                {clientes.map((cliente) => (
                  <option key={cliente.idCliente}>
                    {cliente.nombreCliente}
                  </option>
                ))}
              </Select>
              <Input
                size="sm"
                w={150}
                textTransform={"lowercase"}
                placeholder="Nombre"
                onChange={(e) => {
                  setFilterBy((prevstate) => ({
                    ...prevstate,
                    nombreGeo: e.target.value,
                  }));
                  showGeocercas();
                }}
                value={filterBy.nombreGeo}
              />
              <IconButton
                icon={<MdRefresh />}
                variant={"link"}
                onClick={cleanFilter}
              />
            </HStack>
          )}

          <CrearGeocerca
            initData={initData}
            geocercas={geocercas}
            plantas={plantas}
            planificaciones={planificaciones}
          />
          <IconButton
            icon={<MdFilterList fontSize="20px" />}
            onClick={() => setFilterOn(!filterOn)}
            bg={filterOn ? "brand.naranja" : "brand.fondos_secundarios"}
            borderRadius="50%"
            color={filterOn ? "white" : "black"}
            size={"sm"}
          />
        </HStack>
      </HStack>
      <HStack w={"100%"} justifyContent={"space-between"} px={3}>
        <Stack w={"50%"} h={"lg"}>
          {loading && loadingtable()}
          {!loading && geocercas.length > 0 && (
            <TableContainer overflowY={"auto"}>
              <Table size="sm" variant="simple">
                <Thead
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#F3F6F9",
                    opacity: 1,
                  }}
                >
                  <Tr borderBottom="2px solid black">
                    <Th></Th>
                    <Th>Nombre</Th>
                    <Th>Tipo</Th>
                    <Th>Latitud</Th>
                    <Th>Longitud</Th>
                    <Th textAlign={"center"}>Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {showGeocercas().map((ejemplo) => (
                    <Tr
                      key={ejemplo.idGeocerca}
                      value={ejemplo.idGeocerca}
                      onClick={() => {
                        setSeleccionado(ejemplo);
                        setCenter({
                          lat: ejemplo.latitudCentro,
                          lng: ejemplo.longitudCentro,
                        });
                      }}
                      _hover={{ cursor: "pointer" }}
                      borderBottom={
                        seleccionado.idGeocerca === ejemplo.idGeocerca &&
                        "1px solid orange"
                      }
                    >
                      <Td>
                        <Checkbox
                          isChecked={selectedObjects.some(
                            (selectedItem) =>
                              selectedItem.idGeocerca === ejemplo.idGeocerca
                          )}
                          onChange={(e) => {
                            toggleCheckbox(ejemplo);
                          }}
                        />
                      </Td>
                      <Td>{ejemplo.nombreGeo}</Td>
                      <Td>{handleTipoGeo(ejemplo.tipoGeo)}</Td>
                      <Td>{ejemplo.latitudCentro}</Td>
                      <Td>{ejemplo.longitudCentro}</Td>
                      <Td textAlign={"center"}>
                        <EditarGeocerca
                          geocerca={ejemplo}
                          initData={initData}
                          plantas={plantas}
                          planificaciones={planificaciones}
                        />
                        <EliminarGeocerca
                          geocerca={ejemplo}
                          initData={initData}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!loading && geocercas.length < 1 && (
            <Heading textAlign={"center"} size={"md"}>
              No hay geocercas creadas
            </Heading>
          )}
        </Stack>
        <Stack minW={"md"} w={"50%"} bg={"white"} px={2} pt={1} pb={2}>
          <Box h={"md"} bg={"black"}>
            <GoogleMap
              zoom={17}
              center={center}
              mapTypeId={"satellite"}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              map={mapRef}
              onLoad={(map) => setMap(map)}
            >
              <Marker position={center} map={map} />
              {selectedCoors.map((object) => (
                <Marker
                  key={object.id}
                  position={{ lat: object.lat, lng: object.lng }}
                  map={map}
                />
              ))}

              <Polygon
                paths={seleccionado.puntos}
                options={options}
                map={map}
              />
              {verCamiones &&
                camiones.map((camion) => (
                  <Marker
                    key={camion.idGeoCamion}
                    position={{
                      lat: parseFloat(camion?.latUlitma),
                      lng: parseFloat(camion?.longUltima),
                    }}
                    icon={CambionEnViaje}
                    map={map}
                  />
                ))}
            </GoogleMap>
          </Box>
          <HStack>
            <Checkbox
              isChecked={verCamiones}
              onChange={() => setVerCamiones(!verCamiones)}
            >
              Ver Camiones
            </Checkbox>
          </HStack>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default Geocercas;
