import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  Text,
  Box,
  HStack,
  useToast,
  CircularProgress,
  Heading,
} from "@chakra-ui/react";
import { MdArticle, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getDetenciones, editarDetenciones } from "../helpers/api.helper";

const DetallesDetenciones = (props) => {
  const {
    facturacion,
    estadisticaDia,
    handleDetencionCamion,
    handleFilterChange,
    eT,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [seleccionado, setSeleccionado] = useState({ lat: 0, lng: 0 });
  const [detenciones, setDetenciones] = useState([]);
  const [loading, setLoading] = useState([]);
  const [center, setCenter] = useState({
    lat: -38.33595844666532,
    lng: -68.79461933603682,
  });
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const toast = useToast();
  const idCamion = facturacion.idCamion;
  const fecha = estadisticaDia.diaEstadistica;

  const initData = async () => {
    setLoading(true);
    try {
      let res = await getDetenciones({ idCamion, fecha });

      if (res.status === 200) {
        setDetenciones(res.data[0]);
      } else if (res.status === 204) {
        toast({
          status: "info",
          description: "Cargados correctamente, no hay datos",
          isClosable: true,
          duration: 2000,
        });
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al cargar los datos:", error);
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(url, "_blank");
    setSeleccionado({ lat: lat, lng: lng });
  };

  const openModalAndRunAsyncFunction = () => {
    onOpen();
    initData();
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const calcularSumaTotal = () => {
    let sumaTotal = 0;
    detenciones.detallesPorFechaDetencion.forEach((detail) => {
      if (detail.sumaDetencion === true) {
        sumaTotal += detail.minutosDetenido;
      }
    });
    return sumaTotal;
  };

  const handleIconClick = (obj, evento) => {
    const newDetenciones = detenciones.detallesPorFechaDetencion.map((det) => {
      if (det.idDetencionMasReciente === obj.idDetencionMasReciente) {
        det.sumaDetencion = !det.sumaDetencion;
      }
      return det;
    });
    setDetenciones((prevDetenciones) => ({
      ...prevDetenciones,
      detallesPorFechaDetencion: newDetenciones,
    }));
    calcularSumaTotal();
  };

  const handleSubmit = async () => {
    setLoading(true);
    let detenTemp = detenciones.detallesPorFechaDetencion.map((detencion) => {
      return {
        sumaDetencion: detencion.sumaDetencion,
        idGeoCamionDetencion: detencion.idGeoCamionDetencion,
      };
    });

    let res = await editarDetenciones({ detencion: detenTemp });

    if (res.status === 200) {
      onClose();
      handleDetencionCamion(idCamion, fecha, "detalles");
      handleFilterChange(eT);
    } else {
      toast({
        title: "Error",
        description: "Error al guardar los cambios",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Detalles detenciones">
        <IconButton
          variant={"link"}
          icon={<MdArticle />}
          onClick={openModalAndRunAsyncFunction}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader>Detenciones</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && loadingtable()}
            {!loading && detenciones.detallesPorFechaDetencion.length > 0 && (
              <HStack w={"100%"} justifyContent={"space-around"}>
                <TableContainer w={"50%"} overflowY={"auto"} h={"sm"}>
                  <Table variant="simple" size="sm">
                    <Thead
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#F3F6F9",
                        opacity: 1,
                      }}
                    >
                      <Tr>
                        <Th></Th>
                        <Th>Fecha y Hora</Th>
                        <Th>Lugar</Th>
                        <Th>Tiempo</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {detenciones.detallesPorFechaDetencion &&
                        detenciones.detallesPorFechaDetencion.map((detail) => (
                          <Tr
                            onClick={() => {
                              setSeleccionado({
                                lat: detail.latitud,
                                lng: detail.longitud,
                              });
                              setCenter({
                                lat: detail.latitud,
                                lng: detail.longitud,
                              });
                            }}
                            cursor={"pointer"}
                            key={detail.idDetencionMasReciente}
                          >
                            <Td>
                              {detail.sumaDetencion === true ? (
                                <IconButton
                                  icon={<MdOutlineRemove />}
                                  variant={"link"}
                                  onClick={() => {
                                    handleIconClick(detail, "remove");
                                  }}
                                />
                              ) : (
                                <IconButton
                                  icon={<MdOutlineAdd />}
                                  variant={"link"}
                                  onClick={() => {
                                    handleIconClick(detail, "add");
                                  }}
                                />
                              )}
                            </Td>
                            <Td>{detail.fechaDetencion}</Td>
                            {detail.latitud && (
                              <Td>
                                <Text
                                  onClick={() =>
                                    openGoogleMaps(
                                      detail.latitud,
                                      detail.longitud
                                    )
                                  }
                                  _hover={{
                                    color: "blue",
                                    textDecorationLine: "underline",
                                  }}
                                  cursor={"pointer"}
                                >
                                  {detail.latitud}
                                  {" , "}
                                  {detail.longitud}
                                </Text>
                              </Td>
                            )}
                            {!detail.latitud && <Td>{detail.lugar}</Td>}
                            <Td>{detail.minutosDetenido} mins</Td>
                          </Tr>
                        ))}
                    </Tbody>
                    <Tfoot
                      style={{
                        position: "sticky",
                        bottom: 0,
                        zIndex: 1,
                        backgroundColor: "#F3F6F9",
                        opacity: 1,
                      }}
                    >
                      <Tr>
                        <Th>Total</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th>{calcularSumaTotal()}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>

                <Box w={"lg"} h={"sm"} bg={"black"}>
                  <GoogleMap
                    zoom={17}
                    center={center}
                    mapTypeId="satellite"
                    mapContainerStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                    map={mapRef}
                    onLoad={(map) => setMap(map)}
                  >
                    <Marker
                      position={{
                        lat: seleccionado.lat,
                        lng: seleccionado.lng,
                      }}
                      map={map}
                    />
                  </GoogleMap>
                </Box>
              </HStack>
            )}
            {!loading && detenciones.detallesPorFechaDetencion.length < 1 && (
              <Heading textAlign={"center"} size={"md"}>
                No hay datos para mostrar
              </Heading>
            )}
          </ModalBody>

          <ModalFooter justifyContent={"end"}>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DetallesDetenciones;
