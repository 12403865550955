import React, { useEffect, useState } from "react";
import {
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  IconButton,
  Heading,
  useToast,
  CircularProgress,
  Input,
  FormControl,
  FormLabel,
  Button,
  Text,
} from "@chakra-ui/react";
import { MdOutlineGetApp, MdArrowDropDown } from "react-icons/md";
import { FaUndo } from "react-icons/fa";
import { getTransportista } from "../inventario/helpers/api.helper";
import { editarFacturacion, getFacturacion } from "./helpers/api.helper";
import DetallesDetenciones from "./components/DetallesDetenciones";
import { descargarFacturacion } from "./helpers/api.helper";
import moment from "moment/moment";
import VerViajes from "./components/VerViajes";
import { useSelector } from "react-redux";
import { getDetenciones } from "./helpers/api.helper";

const CertificacionCamiones = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [transportista, setTransportista] = useState([]);
  const [eT, setET] = useState();
  const [facturaciones, setFacturaciones] = useState([]);
  const [detenciones, setDetenciones] = useState([]);
  const [detalles, setDetalle] = useState([]);
  const [filter, setFilter] = useState({
    empresaTransportista: "",
    fechaInicio: "",
    fechaFin: "",
  });
  const toast = useToast();
  let lastValue = null;
  let lastValueII = null;

  const initData = async () => {
    setLoading(true);
    let res;

    res = await getTransportista();

    if (res.status === 200) {
      setTransportista(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        duration: 2000,
        isClosable: true,
        status: "error",
      });
    }

    setLoading(false);
  };

  function filterConsecutiveDuplicates(value, valueII) {
    if (value !== lastValue && valueII !== lastValueII) {
      lastValue = value;
      return value;
    } else {
      return null;
    }
  }

  function filterDuplicates(value, valueII) {
    if (valueII !== lastValueII) {
      lastValueII = valueII;
      return valueII;
    } else {
      return null;
    }
  }

  const handleTipoFacturacion = (value, facturacion, estadisticaDia) => {
    let updatedFacturaciones;
    const editarIndex = facturaciones.findIndex(
      (item) => item.idCamion === facturacion.idCamion
    );

    if (editarIndex !== -1) {
      const editar = facturaciones[editarIndex];
      const indexToUpdate = editar.datosPorCamion.findIndex(
        (e) => e.diaEstadistica === estadisticaDia.diaEstadistica
      );
      if (indexToUpdate !== -1) {
        editar.datosPorCamion[indexToUpdate].tipoFacturacion = value;
        handleCosto(editarIndex, editar.datosPorCamion[indexToUpdate]);
        // Actualizar facturaciones
        facturaciones[editarIndex] = editar;
        // Si necesitas una nueva copia del array facturaciones actualizada, puedes hacerlo así:
        updatedFacturaciones = [...facturaciones];
        // Utiliza la variable updatedFacturaciones en lugar de facturaciones si necesitas la actualización fuera de esta función
      }
    }
    setFacturaciones(updatedFacturaciones);
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    // Actualizar el estado del filtro
    await setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Llamar a la función asincrónica y manejar la respuesta
    try {
      setLoading(true); // Establecer el estado de carga a verdadero

      let res = await getFacturacion({
        empresa:
          name === "empresaTransportista" ? value : filter.empresaTransportista,
        fechaHasta: name === "fechaFin" ? value : filter.fechaFin,
        fecha: name === "fechaInicio" ? value : filter.fechaInicio,
      });

      if (res.status === 200) {
        setFacturaciones(res.data);
        setDetalle(res.data);
      } else if (res.status === 204) {
        toast({
          title: "No existen certificaciones",
          duration: 2000,
          isClosable: true,
          status: "info",
        });
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los datos",
          duration: 2000,
          isClosable: true,
          status: "error",
        });
      }

      res = await getDetenciones();
      if (res.status === 200) {
        setDetenciones(res.data);
      } else if (res.status === 204) {
        toast({
          status: "info",
          description: "Cargados correctamente, no hay datos",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      console.error("Error al obtener la facturación:", error);
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        duration: 2000,
        isClosable: true,
        status: "error",
      });
    } finally {
      setLoading(false); // Establecer el estado de carga a falso, independientemente del resultado
    }
  };

  const handleDetencionCamion = (facturacion, estadisticaDia, evento) => {
    let camion;
    if (evento === "certificacion") {
      camion = detenciones.filter(
        (detencion) => detencion.idCamion === facturacion.idCamion
      );
    }
    if (evento === "detalles") {
      camion = detenciones.filter(
        (detencion) => detencion.idCamion === facturacion
      );
    }
    camion = camion[0].detallesPorFechaDetencion.filter(
      (camion) =>
        moment(camion.fechaDetencion).format("DD-MM-YYYY") ===
        moment(estadisticaDia.diaEstadistica).format("DD-MM-YYYY")
    );
    let a = camion
      .filter((e) => e.sumaDetencion === true)
      .reduce((acc, val) => acc + val.minutosDetenido, 0);
    return (a / 60).toFixed(1);
  };

  const handleDownload = async () => {
    let temp = facturaciones.filter((e) => e.datosPorCamion);
    let data = temp.flatMap((elemento) =>
      elemento.datosPorCamion.map((dia) => {
        return {
          diaEstadistica: dia.diaEstadistica,
          camion: elemento.camion,
          empresaTransportista: elemento.empresaTransportista,
          cantidadViajes: dia.totalCantidadViajes,
          kmsRecorridos: dia.totalKmsRecorridos,
          tiempoStandby: dia.totalTiempoStandby,
          tipoFacturacion: dia.tipoFacturacion,
          facturacionConfirmada: dia.facturacionConfirmada,
          usuarioUpdate: dia.userConfirmacion,
          fechaConfirmacion: dia.fechaConfirmacion,
          numeroDetenciones: dia.numeroDetenciones,
        };
      })
    );
    let response = await descargarFacturacion({ facturacion: data });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    if (filter.empresaTransportista) {
      if (filter.fechaInicio) {
        link.setAttribute(
          "download",
          `Certificacion-camiones-${filter.empresaTransportista}-${filter.fechaInicio}.xlsx`
        );
      } else if (filter.fechaFin) {
        link.setAttribute(
          "download",
          `Certificacion-camiones-${filter.empresaTransportista}-${filter.fechaInicio}.xlsx`
        );
      } else {
        link.setAttribute(
          "download",
          `Certificacion-camiones-${filter.empresaTransportista}.xlsx`
        );
      }
    } else {
      link.setAttribute("download", `Certificacion-camiones.xlsx`);
    }
    document.body.appendChild(link);
    link.click();
  };

  const handleUpdate = async (elem, estadisticaDia) => {
    setLoading(true);
    let data = {
      diaEstadistica: estadisticaDia.diaEstadistica,
      facturacionConfirmada: true,
      tipoFacturacion: estadisticaDia.tipoFacturacion,
      idCamion: elem.idCamion,
    };

    if (estadisticaDia.tipoFacturacion) {
      let res = await editarFacturacion(data);
      if (res.status === 200) {
        let editar = facturaciones.find(
          (item) => item.idCamion === elem.idCamion
        );
        const indexFechaDetencion = facturaciones.findIndex(
          (detalle) => detalle.idCamion === elem.idCamion
        );
        if (editar) {
          let editarII = editar.datosPorCamion.map((dato) => {
            if (dato.diaEstadistica === estadisticaDia.diaEstadistica) {
              return {
                ...dato,
                facturacionConfirmada: true,
                fechaConfirmacion: moment().format("MM/DD/YYYY HH:mm"),
                usuarioUpdate: user.username,
              };
            }
            return dato;
          });
          editar.datosPorCamion = editarII;
        }
        facturaciones[indexFechaDetencion] = editar;
        setFacturaciones(facturaciones);
        toast({
          title: "Exito",
          description: "Facturación editada correctamente",
          duration: 2000,
          isClosable: true,
          status: "success",
        });

        initData();
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los datos",
          duration: 2000,
          isClosable: true,
          status: "error",
        });
      }
    }

    setLoading(false);
  };

  const handleUndo = async (elem, estadisticaDia) => {
    let data = {
      diaEstadistica: estadisticaDia.diaEstadistica,
      facturacionConfirmada: false,
      idCamion: elem.idCamion,
    };

    let res = await editarFacturacion(data);
    if (res.status === 200) {
      let editar = facturaciones.find(
        (item) => item.idCamion === elem.idCamion
      );
      const indexFechaDetencion = facturaciones.findIndex(
        (detalle) => detalle.idCamion === elem.idCamion
      );
      if (editar) {
        let editarII = editar.datosPorCamion.map((dato) => {
          if (dato.diaEstadistica === estadisticaDia.diaEstadistica) {
            return {
              ...dato,
              facturacionConfirmada: false,
              fechaConfirmacion: moment().format("MM/DD/YYYY HH:mm"),
              usuarioUpdate: user.username,
            };
          }
          return dato;
        });
        editar.datosPorCamion = editarII;
      }
      facturaciones[indexFechaDetencion] = editar;
      setFacturaciones(facturaciones);
      initData();
      toast({
        title: "Exito",
        description: "Acción revertida correctamente",
        duration: 2000,
        isClosable: true,
        status: "success",
      });
    } else {
      toast({
        title: "Error",
        description: "Error al revertir",
        duration: 2000,
        isClosable: true,
        status: "error",
      });
    }
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleCosto = (facturacion, objeto) => {
    if (objeto.tipoFacturacion === "MENSUAL") {
      return facturacion.costoCompleto;
    } else if (objeto.tipoFacturacion === "PENALIDAD MENSUAL") {
      return facturacion.costoPenalizado;
    } else if (objeto.tipoFacturacion === "FLOTA PROPIA") {
      return facturacion.costoCompleto;
    } else if (objeto.tipoFacturacion === "PENALIDAD FLOTA PROPIA") {
      return facturacion.costoPenalizado;
    } else if (objeto.tipoFacturacion === "A DEMANDA") {
      return facturacion.costoCompleto;
    } else if (objeto.tipoFacturacion === "A DEMANDA 12hs") {
      return facturacion.costoADemanda12;
    } else if (objeto.tipoFacturacion === "PENALIDAD A DEMANDA") {
    } else {
      return null;
    }
  };

  useEffect(() => {
    initData();
  }, []);
  console.log(facturaciones);
  return (
    <Stack w="100%" h="100%" px={3}>
      <HStack w="100%" justifyContent="space-between" px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Certificación de Camiones
        </Heading>
        <IconButton
          variant="outline"
          border="1px solid #FF8200"
          icon={<MdOutlineGetApp fontSize="20px" />}
          color="brand.naranja"
          onClick={handleDownload}
          _hover={{ bg: "brand.naranja", color: "white" }}
        />
      </HStack>

      <HStack w="100%" justifyContent={"space-around"} bg={"white"} p={2}>
        <FormControl>
          <FormLabel fontSize={"14px"}>Empresa Transportista</FormLabel>
          <Select
            placeholder="- Seleccione -"
            variant="filled"
            name={"empresaTransportista"}
            icon={<MdArrowDropDown />}
            onChange={(e) => {
              setET(e);
              handleFilterChange(e);
            }}
            w={"2xs"}
            size={"sm"}
          >
            {transportista.map((transportista) => (
              <option
                key={transportista.idEmpresaT}
                value={transportista.nombreEmpresaT}
              >
                {transportista.nombreEmpresaT}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel fontSize={"14px"}>Fecha Inicio</FormLabel>
          <Input
            variant="flushed"
            value={filter.fechaInicio}
            name={"fechaInicio"}
            onChange={(e) => {
              handleFilterChange(e);
            }}
            w={"2xs"}
            type={"date"}
            size={"sm"}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={"14px"}>Fecha Fin</FormLabel>
          <Input
            variant="flushed"
            value={filter.fechaFin}
            name={"fechaFin"}
            onChange={(e) => {
              handleFilterChange(e);
            }}
            min={moment(filter.fechaInicio).add(1, "d")}
            w={"2xs"}
            type={"date"}
            size={"sm"}
          />
        </FormControl>
      </HStack>

      <Stack w="100%">
        {loading && loadingtable()}
        {!loading && facturaciones.length > 0 && (
          <TableContainer overflowY={"auto"} maxH={"430px"}>
            <Table variant="simple" size={"sm"}>
              <Thead>
                <Tr>
                  <Th>Camión</Th>
                  <Th>Interno</Th>
                  <Th>Fechas</Th>
                  <Th>HS inactivas</Th>
                  <Th>Kms Realizados</Th>
                  <Th>Viajes (cant.)</Th>
                  <Th>Tipo facturacion</Th>
                  <Th>Costo</Th>
                  <Th>Estado</Th>
                  <Th colSpan={2}>Detalle</Th>
                </Tr>
              </Thead>

              {facturaciones.map((facturacion) => (
                <Tbody overflowY={"auto"} key={facturacion.idCamion}>
                  {facturacion.datosPorCamion &&
                    facturacion.datosPorCamion.map((estadisticaDia, index) => (
                      <Tr key={index}>
                        <Td>
                          {filterConsecutiveDuplicates(
                            facturacion.camion,
                            facturacion.nombreCamion
                          )}
                        </Td>
                        <Td>
                          {filterDuplicates(
                            facturacion.camion,
                            facturacion.nombreCamion
                          )}
                        </Td>
                        <Td>
                          {moment
                            .utc(estadisticaDia.diaEstadistica)
                            .format("DD-MM-YYYY")}
                        </Td>
                        <Td>
                          {handleDetencionCamion(
                            facturacion,
                            estadisticaDia,
                            "certificacion"
                          )}
                          <DetallesDetenciones
                            facturacion={facturacion}
                            estadisticaDia={estadisticaDia}
                            handleDetencionCamion={handleDetencionCamion}
                            handleFilterChange={handleFilterChange}
                            eT={eT}
                          />
                        </Td>
                        <Td>{estadisticaDia.totalKmsRecorridos || 0}</Td>
                        <Td>
                          {estadisticaDia.totalCantidadViajes || 0}
                          <VerViajes
                            facturacion={facturacion}
                            estadisticaDia={estadisticaDia}
                          />
                        </Td>
                        <Td>
                          <HStack>
                            {estadisticaDia.facturacionConfirmada && (
                              <Text>{estadisticaDia.tipoFacturacion}</Text>
                            )}
                            {!estadisticaDia.facturacionConfirmada && (
                              <Select
                                placeholder="- Tipo -"
                                variant={"unstyled"}
                                value={estadisticaDia.tipoFacturacion}
                                onChange={(e) =>
                                  handleTipoFacturacion(
                                    e.target.value,
                                    facturacion,
                                    estadisticaDia
                                  )
                                }
                              >
                                {facturacion.tipoContrato === "MENSUAL" && (
                                  <>
                                    <option value={"MENSUAL"}>MENSUAL</option>
                                    <option value={"PENALIDAD MENSUAL"}>
                                      PENALIDAD MENSUAL
                                    </option>
                                  </>
                                )}
                                {facturacion.tipoContrato === "A DEMANDA" && (
                                  <>
                                    <option value={"A DEMANDA 12hs"}>
                                      A DEMANDA 12hs
                                    </option>
                                    <option value={"A DEMANDA"}>
                                      A DEMANDA
                                    </option>
                                    <option value={"PENALIDAD A DEMANDA"}>
                                      PENALIDAD A DEMANDA
                                    </option>
                                  </>
                                )}
                                {facturacion.tipoContrato ===
                                  "FLOTA PROPIA" && (
                                  <>
                                    <option value={"FLOTA PROPIA"}>
                                      FLOTA PROPIA
                                    </option>
                                    <option value={"PENALIDAD FLOTA PROPIA"}>
                                      PENALIDAD FLOTA PROPIA
                                    </option>
                                  </>
                                )}
                                <option value={"INACTIVO"}>INACTIVO</option>
                              </Select>
                            )}
                          </HStack>
                        </Td>
                        <Td>{handleCosto(facturacion, estadisticaDia)}</Td>
                        {(user.idRol === "admin" ||
                          user.idRol === "coorOp") && (
                          <Td>
                            <Button
                              variant={"link"}
                              isDisabled={estadisticaDia.facturacionConfirmada}
                              onClick={() =>
                                handleUpdate(facturacion, estadisticaDia)
                              }
                              color={
                                estadisticaDia.facturacionConfirmada
                                  ? "brand.naranja"
                                  : "gray.400"
                              }
                            >
                              {estadisticaDia.facturacionConfirmada
                                ? "Confirmado"
                                : "A confirmar"}
                            </Button>
                            {estadisticaDia.facturacionConfirmada && (
                              <IconButton
                                icon={<FaUndo />}
                                variant={"link"}
                                onClick={() =>
                                  handleUndo(facturacion, estadisticaDia)
                                }
                              />
                            )}
                          </Td>
                        )}
                        {estadisticaDia.nombreUsuario && (
                          <Td>
                            {estadisticaDia.nombreUsuario}
                            {" - "}
                            {moment
                              .utc(estadisticaDia.fechaConfirmacion)
                              .format("DD/MM/YYYY HH:mm")}
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              ))}
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Stack>
  );
};

export default CertificacionCamiones;
