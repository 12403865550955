import React, { useEffect } from "react";
import {
  Step,
  StepIcon,
  StepIndicator,
  StepStatus,
  Stepper,
  useSteps,
  StepSeparator,
  StepNumber,
  Progress,
  Box,
  Tooltip,
} from "@chakra-ui/react";

const steps = [
  { title: "First", description: "Contact Info" },
  { title: "Second", description: "Date & Time" },
  { title: "Third", description: "Select Rooms" },
  { title: "Second", description: "Date & Time" },
  { title: "Third", description: "Select Rooms" },
];

const Example = (props) => {
  const { seleccionado } = props;
  let progressPercent;

  if (seleccionado.scAsociados) {
    progressPercent = 5;
  } else if (seleccionado.UserPaso4) {
    progressPercent = 4;
  } else if (seleccionado.detallePlantas) {
    progressPercent = 3;
  } else if (seleccionado.UserPaso2) {
    progressPercent = 2;
  } else {
    progressPercent = 1;
  }
  const { activeStep, setActiveStep } = useSteps({
    index: progressPercent,
    count: steps.length,
  });

  useEffect(() => {
    setActiveStep(progressPercent);
  }, [progressPercent]);

  return (
    <Box w="100%" position="relative">
      <Stepper size="sm" py={1} index={activeStep} gap="0" colorScheme="orange">
        {steps.map((step, index) => (
          <Tooltip
            key={step}
            label={
              index === 0
                ? `${seleccionado.UserPaso1.nombre}`
                : index === 1
                ? `${seleccionado.UserPaso2?.nombre}`
                : index === 2
                ? `${seleccionado.UserPaso3?.nombre}`
                : index === 3
                ? `${seleccionado.UserPaso4?.nombre}`
                : ""
            }
          >
            <Step gap="0">
              <StepIndicator
                bg="brand.naranja"
                textColor="white"
                border="1px solid gris"
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <StepSeparator bg="brand.gris_medio" _horizontal={{ ml: "0" }} />
            </Step>
          </Tooltip>
        ))}
      </Stepper>
      <Progress
        value={progressPercent}
        position="absolute"
        height="3px"
        width="full"
        top="10px"
        bg="brand.naranja"
        zIndex={-1}
      />
    </Box>
  );
};

export default Example;
