import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  Heading,
  useToast,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import EliminarSandcubes from "./components/sandcubes/EliminarSandcubes";
import { getSandcubes } from "../operaciones/helpers/api.helper";
import {
  MdAddCircleOutline,
  MdOutlineCreate,
  MdRefresh,
  MdCheck,
  MdFilterList,
  MdOutlineImportExport,
} from "react-icons/md";
import { useSelector } from "react-redux";
import EdicionMultiple from "./components/sandcubes/EdicionMultiple";
import { getPlanificaciones } from "../operaciones/helpers/api.helper";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../utils/sortData";

const Sandcubes = () => {
  const [sandcubes, setSandcubes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [planiActivas, setPlaniActivas] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [filterBy, setFilterBy] = useState({
    malla: [],
    statusSandCube: [],
    condicion: [],
    wetsand: [],
    set: [],
    ubicacion: [],
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res = await getSandcubes();
    if (res.status === 200) {
      setSandcubes(res.data);
    } else if (res.status === 404) {
      toast({
        status: "success",
        description: "Cargados correctamente",
        isClosable: true,
        duration: 2000,
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los sandcubes",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }

    res = await getPlanificaciones({ estado: "activo" });

    if (res.status === 200) {
      setPlaniActivas(res.data);
    }

    setLoading(false);
  };
  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleChange = (item) => {
    const selectedIndex = selectedItems.indexOf(item);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(sandcubes);
    }
    setSelectAll(!selectAll);
  };

  const handleFilterSelection = (filterName, value) => {
    setFilterBy((prevstate) => ({
      ...prevstate,
      [filterName]: prevstate[filterName].includes(value)
        ? prevstate[filterName].filter((val) => val !== value)
        : [...prevstate[filterName], value],
    }));
    setSelectedFilter((prevSelected) => ({
      ...prevSelected,
      [filterName]: value,
    }));
    showFiltrados();
  };

  const showFiltrados = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return sandcubes;
    }
    return sandcubes.filter((sandcube) => {
      const { malla, statusSandCube, condicion, wetsand, set, ubicacion } =
        filterBy;

      return (
        (!malla.length || malla.includes(sandcube.malla)) &&
        (!statusSandCube.length ||
          statusSandCube.includes(sandcube.statusSandCube)) &&
        (!set.length || set.includes(sandcube.padLocacion)) &&
        (!ubicacion.length || ubicacion.includes(sandcube.ubicacionSandCube)) &&
        (!condicion.length || condicion.includes(sandcube.condicion)) &&
        (!wetsand.length || wetsand.includes(sandcube.wetsandSC.toString()))
      );
    });
  };
  const cleanFilter = () => {
    setFilterBy({
      malla: [],
      statusSandCube: [],
      condicion: [],
      wetsand: [],
      set: [],
      ubicacion: [],
    });
  };
  const nombresSet = sandcubes.reduce((nombresUnicos, scs) => {
    if (!nombresUnicos.includes(scs.padLocacion)) {
      nombresUnicos.push(scs.padLocacion);
    }
    return nombresUnicos;
  }, []);

  const nombresUbicacion = sandcubes.reduce((nombresUnicos, scs) => {
    if (!nombresUnicos.includes(scs.ubicacionSandCube)) {
      nombresUnicos.push(scs.ubicacionSandCube);
    }
    return nombresUnicos;
  }, []);

  useEffect(() => {
    initData();
  }, []);
  console.log(sandcubes);
  return (
    <Stack w="100%" h="xl">
      <HStack w="100%" justifyContent="space-between" px={5}>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Sandcubes
        </Heading>
        <HStack>
          <EdicionMultiple
            selectedItems={selectedItems}
            planiActivas={planiActivas}
            initData={initData}
            setSelectedItems={setSelectedItems}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
          />
          {(user.idRol === "admin" || user.idRol === "coorOp") && (
            <Button
              size="sm"
              variant="outline"
              border="1px solid #FF8200"
              leftIcon={<MdAddCircleOutline fontSize="20px" />}
              color="brand.naranja"
              onClick={() => navigate("/inventario/sandcubes/nuevo")}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Nuevo sandcube
            </Button>
          )}
          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Por status
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {filterBy.statusSandCube.includes("Vacio") && (
                            <MdCheck />
                          )}
                          <ListItem
                            style={
                              filterBy["statusSandCube"].includes("Vacio")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("statusSandCube", "Vacio");
                            }}
                          >
                            Vacio
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.statusSandCube.includes("Lleno") && (
                            <MdCheck />
                          )}

                          <ListItem
                            style={
                              filterBy["statusSandCube"].includes("Lleno")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("statusSandCube", "Lleno");
                            }}
                          >
                            Lleno
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Tipo de malla
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {filterBy.malla.includes("100") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("100")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "100");
                            }}
                          >
                            100
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("30/70") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("30/70")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "30/70");
                            }}
                          >
                            30/70
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("70/40") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("70/40")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "70/40");
                            }}
                          >
                            70/40
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("50/70") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("50/70")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "50/70");
                            }}
                          >
                            50/70
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("40/80") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("40/80")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "40/80");
                            }}
                          >
                            40/80
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("40/70") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("40/70")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "40/70");
                            }}
                          >
                            40/70
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("30/50") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("30/50")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "30/50");
                            }}
                          >
                            30/50
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.malla.includes("20/40") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["malla"].includes("20/40")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("malla", "20/40");
                            }}
                          >
                            20/40
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Activo/Inactivo
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {filterBy.condicion.includes("activo") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["condicion"].includes("activo")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("condicion", "activo");
                            }}
                          >
                            Activo
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.condicion.includes("inactivo") && (
                            <MdCheck />
                          )}

                          <ListItem
                            style={
                              filterBy["condicion"].includes("inactivo")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("condicion", "inactivo");
                            }}
                          >
                            Inactivo
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Wetsand
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {filterBy.wetsand.includes("true") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["wetsand"].includes("true")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("wetsand", "true");
                            }}
                          >
                            SI
                          </ListItem>
                        </HStack>
                        <HStack>
                          {filterBy.wetsand.includes("false") && <MdCheck />}

                          <ListItem
                            style={
                              filterBy["wetsand"].includes("false")
                                ? { backgroundColor: "lightgray" }
                                : {}
                            }
                            onClick={() => {
                              handleFilterSelection("wetsand", "false");
                            }}
                          >
                            NO
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>SET</AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        {nombresSet.map((set) => (
                          <HStack key={set}>
                            {filterBy.set.includes(set) && <MdCheck />}

                            <ListItem
                              style={
                                filterBy["set"].includes(set)
                                  ? { backgroundColor: "lightgray" }
                                  : {}
                              }
                              onClick={() => {
                                handleFilterSelection("set", set);
                              }}
                            >
                              {set}
                            </ListItem>
                          </HStack>
                        ))}
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Ubicación
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        {nombresUbicacion.map((ubicacion) => (
                          <HStack key={ubicacion}>
                            {filterBy.ubicacion.includes(ubicacion) && (
                              <MdCheck />
                            )}

                            <ListItem
                              style={
                                filterBy["ubicacion"].includes(ubicacion)
                                  ? { backgroundColor: "lightgray" }
                                  : {}
                              }
                              onClick={() => {
                                handleFilterSelection("ubicacion", ubicacion);
                              }}
                            >
                              {ubicacion}
                            </ListItem>
                          </HStack>
                        ))}
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      {loading && loadingtable()}
      {!loading && sandcubes.length > 0 && (
        <TableContainer overflowY={"auto"}>
          <Table size="sm" h="max-content" variant="simple">
            <Thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#F3F6F9",
                opacity: 1,
              }}
            >
              <Tr borderBottom="2px solid black">
                <Th>
                  <Checkbox isChecked={selectAll} onChange={handleSelectAll} />
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon5").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon5").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "nombreSandCube",
                        sandcubes
                      );
                      setSortField("nombreSandCube");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    ID Sandcube
                    {sortDirection === "asc" &&
                      sortField === "nombreSandCube" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "nombreSandCube" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon5" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon5").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon5").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "statusSandCube",
                        sandcubes
                      );
                      setSortField("statusSandCube");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Status
                    {sortDirection === "asc" &&
                      sortField === "statusSandCube" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "statusSandCube" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "padLocacion",
                        sandcubes
                      );
                      setSortField("padLocacion");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Set
                    {sortDirection === "asc" && sortField === "padLocacion" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "padLocacion" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon1" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>Ubicación</Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon4").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon4").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "malla",
                        sandcubes
                      );
                      setSortField("malla");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Malla
                    {sortDirection === "asc" && sortField === "malla" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "malla" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon4" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>Wetsand</Th>
                <Th>Tns.</Th>
                <Th>Activos/Inactivo</Th>
                <Th>Carga</Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "padLocacion",
                        sandcubes
                      );
                      setSortField("padLocacion");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Usos PAD
                    {sortDirection === "asc" && sortField === "padLocacion" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "padLocacion" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon3" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "usosTotales",
                        sandcubes
                      );
                      setSortField("usosTotales");
                      setSortDirection(direction);
                      setSandcubes(sortedData);
                    }}
                    _hover={<Icon as={MdOutlineImportExport} />}
                  >
                    Usos Totales
                    {sortDirection === "asc" && sortField === "usosTotales" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "usosTotales" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon2" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>RFID</Th>
                {(user.idRol === "admin" || user.idRol === "coorOp") && (
                  <Th textAlign={"center"}>Acciones</Th>
                )}
              </Tr>
            </Thead>
            {!loading && sandcubes.length > 0 && (
              <Tbody>
                {showFiltrados().map((sandcube) => (
                  <Tr key={sandcube.idSandCube}>
                    <Td>
                      <Checkbox
                        isChecked={selectedItems.includes(sandcube)}
                        onChange={() => handleChange(sandcube)}
                      />
                    </Td>
                    <Td>{sandcube.nombreSandCube}</Td>
                    <Td>{sandcube.statusSandCube}</Td>
                    <Td>{sandcube.padLocacion || "-"}</Td>
                    <Td>{sandcube.ubicacionSandCube || "-"}</Td>
                    <Td>{sandcube.malla || "-"}</Td>
                    <Td>{sandcube.wetsandSC ? "SI" : "NO"}</Td>
                    <Td>{sandcube.toneladasSandCube || "-"}</Td>
                    <Td
                      color={
                        sandcube.condicion === "activo"
                          ? "green"
                          : sandcube.condicion === "inactivo"
                          ? "red"
                          : "black"
                      }
                    >
                      {sandcube.condicion === "activo"
                        ? "Activo"
                        : sandcube.condicion === "inactivo"
                        ? "Inactivo"
                        : "--"}
                    </Td>
                    <Td>{sandcube.porcentajeSandCube}%</Td>
                    <Td>{sandcube.usosPAD}</Td>
                    <Td>{sandcube.usosTotales}</Td>
                    <Td></Td>
                    {(user.idRol === "admin" || user.idRol === "coorOp") && (
                      <Td textAlign={"center"}>
                        <Tooltip label="Editar Sandcubes">
                          <IconButton
                            variant="link"
                            icon={<MdOutlineCreate fontSize="20px" />}
                            onClick={() =>
                              navigate(
                                `/inventario/sandcubes/editar/${sandcube.idSandCube}`
                              )
                            }
                            _hover={{ color: "brand.naranja" }}
                          />
                        </Tooltip>
                        <EliminarSandcubes
                          sandcube={sandcube}
                          initData={initData}
                        />
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            )}
            {!loading && sandcubes.length < 1 && (
              <Tbody>
                <Tr>
                  <Td
                    colSpan={11}
                    textAlign="center"
                    fontWeight="bold"
                    color="brand.gris_primario"
                  >
                    No hay sandcubes creados
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>
        </TableContainer>
      )}
      {!loading && sandcubes.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Sandcubes;
