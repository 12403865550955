import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  HStack,
  CircularProgress,
  Box,
  Heading,
} from "@chakra-ui/react";
import { MdOutlineGetApp } from "react-icons/md";
import { descargarReportesViajes } from "../helpers/api.helper";
import { getReportesViajes } from "../helpers/api.helper";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../../utils/sortData";
import moment from "moment";

const VerViajes = (props) => {
  const { facturacion, estadisticaDia } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [reportes, setReportes] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const toast = useToast();
  const camionRV = facturacion.camion;
  const fechaRV = estadisticaDia.diaEstadistica;

  const initData = async () => {
    setLoading(true);
    try {
      let res = await getReportesViajes({ camionRV, fechaRV });

      if (res.status === 200) {
        setReportes(res.data.reportes);
      } else if (res.status === 404) {
        toast({
          status: "info",
          description: "Cargados correctamente, no hay datos",
          isClosable: true,
          duration: 2000,
        });
      } else {
        toast({
          title: "Error",
          description: "Error al cargar los reportes",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error al cargar los reportes:", error);
      toast({
        title: "Error",
        description: "Error al cargar los reportes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const openModalAndRunAsyncFunction = () => {
    onOpen();
    initData();
  };

  const handleColorEstado = (e) => {
    switch (e) {
      case "Finalizado":
        return "brand.verde";
      case "Caducado":
        return "brand.rojo";
      case "En curso":
        return "#fbb034";
      case "cancelado":
        return "brand.gris_medio";
      default:
        return "-";
    }
  };
  const handleTextoEstado = (e) => {
    switch (e) {
      case "En curso":
        return "En curso";
      case "Finalizado":
        return "Finalizado";
      case "cancelado":
        return "Cancelado";
      case "Caducado":
        return "Caducado";
      default:
        return "-";
    }
  };
  const handleTextoUbicacion = (e) => {
    switch (e) {
      case "standby Pozo":
        return "Stand By en pozo";
      case "vuelta Planta":
        return "En tránsito a pozo";
      case "camino Planta":
        return "En tránsito a Planta";
      case "standby Planta":
        return "En tránsito a Planta";

      default:
        return "-";
    }
  };

  const handleDownload = async () => {
    let response = await descargarReportesViajes({ reportes: reportes });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Reportes-viajes-${facturacion.camion}-del-${moment(
        facturacion.diaEstadistica
      ).format("DD/MM/YYYY")}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };
  return (
    <>
      <Button variant={"link"} onClick={openModalAndRunAsyncFunction}>
        Ver
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"7xl"}>
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader>
            Viajes de {camionRV} del {moment(fechaRV).format("DD/MM/YYYY")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && loadingtable()}
            {!loading && reportes.length > 0 && (
              <TableContainer overflowY={"auto"} style={{ maxHeight: "495px" }}>
                <Table size="sm" variant="simple">
                  <Thead
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "#F3F6F9",
                      opacity: 1,
                    }}
                  >
                    <Tr>
                      <Th>
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "idViaje",
                              reportes
                            );
                            setSortField("idViaje");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          ID viaje
                          {sortDirection === "asc" &&
                            sortField === "idViaje" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "idViaje" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "statusRV",
                              reportes
                            );
                            setSortField("statusRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Estado
                          {sortDirection === "asc" &&
                            sortField === "statusRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "statusRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "idViaje",
                              reportes
                            );
                            setSortField("idViaje");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Locacion
                          {sortDirection === "asc" &&
                            sortField === "idViaje" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "idViaje" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "padDestino",
                              reportes
                            );
                            setSortField("padDestino");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          PAD destino
                          {sortDirection === "asc" &&
                            sortField === "padDestino" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "padDestino" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "transporteRV",
                              reportes
                            );
                            setSortField("transporteRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Transporte
                          {sortDirection === "asc" &&
                            sortField === "transporteRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "transporteRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "camionRV",
                              reportes
                            );
                            setSortField("camionRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Camion
                          {sortDirection === "asc" &&
                            sortField === "camionRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "camionRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "sandCube1",
                              reportes
                            );
                            setSortField("sandCube1");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          SCs asociaciados
                          {sortDirection === "asc" &&
                            sortField === "sandCube1" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "sandCube1" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "sandCube2",
                              reportes
                            );
                            setSortField("sandCube2");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          SCs asociaciados
                          {sortDirection === "asc" &&
                            sortField === "sandCube2" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "sandCube2" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "plantaCarga",
                              reportes
                            );
                            setSortField("plantaCarga");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Planta Carga
                          {sortDirection === "asc" &&
                            sortField === "plantaCarga" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "plantaCarga" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "tsPozoOut",
                              reportes
                            );
                            setSortField("tsPozoOut");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          TS Pozo out
                          {sortDirection === "asc" &&
                            sortField === "tsPozoOut" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "tsPozoOut" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "tsPlantaIn",
                              reportes
                            );
                            setSortField("tsPlantaIn");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          TS Planta in
                          {sortDirection === "asc" &&
                            sortField === "tsPlantaIn" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "tsPlantaIn" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "tsPlantaOut",
                              reportes
                            );
                            setSortField("tsPlantaOut");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          TS Planta out
                          {sortDirection === "asc" &&
                            sortField === "tsPlantaOut" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "tsPlantaOut" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "tsPozoIn",
                              reportes
                            );
                            setSortField("tsPozoIn");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          TS Pozo in
                          {sortDirection === "asc" &&
                            sortField === "tsPozoIn" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "tsPozoIn" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "eta",
                              reportes
                            );
                            setSortField("eta");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          ETA
                          {sortDirection === "asc" && sortField === "eta" && (
                            <ArrowUpIcon />
                          )}
                          {sortDirection === "des" && sortField === "eta" && (
                            <ArrowDownIcon />
                          )}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "standByPlanta",
                              reportes
                            );
                            setSortField("standByPlanta");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Standby en Planta (mins.)
                          {sortDirection === "asc" &&
                            sortField === "standByPlanta" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "standByPlanta" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "standByPozo",
                              reportes
                            );
                            setSortField("standByPozo");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Standby en Pozo (mins.)
                          {sortDirection === "asc" &&
                            sortField === "standByPozo" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "standByPozo" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "idViaje",
                              reportes
                            );
                            setSortField("idViaje");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          ID viaje
                          {sortDirection === "asc" &&
                            sortField === "idViaje" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "idViaje" && <ArrowDownIcon />}
                        </span>
                        Tiempo Vuelta (mins.)
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "kms",
                              reportes
                            );
                            setSortField("kms");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Kms.
                          {sortDirection === "asc" && sortField === "kms" && (
                            <ArrowUpIcon />
                          )}
                          {sortDirection === "des" && sortField === "kms" && (
                            <ArrowDownIcon />
                          )}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "fechaRV",
                              reportes
                            );
                            setSortField("fechaRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Fecha reporte
                          {sortDirection === "asc" &&
                            sortField === "fechaRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "fechaRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "cargaTotal",
                              reportes
                            );
                            setSortField("cargaTotal");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Carga Total
                          {sortDirection === "asc" &&
                            sortField === "cargaTotal" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "cargaTotal" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "demoras",
                              reportes
                            );
                            setSortField("demoras");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Demoras (mins.)
                          {sortDirection === "asc" &&
                            sortField === "demoras" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "demoras" && <ArrowDownIcon />}
                        </span>
                      </Th>

                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "numRemitoRV",
                              reportes
                            );
                            setSortField("numRemitoRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Nro. de Remito
                          {sortDirection === "asc" &&
                            sortField === "numRemitoRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "numRemitoRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                      <Th>
                        {" "}
                        <span
                          className="sortable"
                          onClick={() => {
                            const { sortedData, direction } = sortData(
                              "ToneladasRV",
                              reportes
                            );
                            setSortField("ToneladasRV");
                            setSortDirection(direction);
                            setReportes(sortedData);
                          }}
                        >
                          Toneladas
                          {sortDirection === "asc" &&
                            sortField === "ToneladasRV" && <ArrowUpIcon />}
                          {sortDirection === "des" &&
                            sortField === "ToneladasRV" && <ArrowDownIcon />}
                        </span>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody h={"100%"} overflowY={"auto"}>
                    {reportes.map((reporte) => (
                      <Tr key={reporte.idReporteViaje}>
                        <Td>{reporte.idReporteViaje}</Td>
                        <Td color={handleColorEstado(reporte.statusRV)}>
                          {handleTextoEstado(reporte.statusRV)}
                        </Td>
                        <Td>{reporte.status2RV}</Td>
                        <Td>{reporte.padDestino}</Td>
                        <Td>{reporte.transporteRV}</Td>
                        <Td>{reporte.camionRV}</Td>
                        <Td>{reporte.sandCube1}</Td>
                        <Td>{reporte.sandCube2}</Td>
                        <Td>{reporte.plantaCarga}</Td>
                        <Td>
                          {reporte.tsPozoOut &&
                            moment
                              .utc(reporte.tsPozoOut)
                              .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>
                          {reporte.tsPlantaIn &&
                            moment
                              .utc(reporte.tsPlantaIn)
                              .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>
                          {reporte.tsPlantaOut &&
                            moment
                              .utc(reporte.tsPlantaOut)
                              .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>
                          {reporte.tsPozoIn &&
                            moment
                              .utc(reporte.tsPozoIn)
                              .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>
                          {reporte.eta &&
                            moment.utc(reporte.eta).format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>{reporte.standByPlanta}</Td>
                        <Td>{reporte.standByPozo}</Td>
                        <Td>{reporte.tiempoVuelta}</Td>
                        <Td>{reporte.kms}</Td>
                        <Td>
                          {reporte.fechaRV &&
                            moment.utc(reporte.fechaRV).format("DD/MM/YYYY")}
                        </Td>
                        <Td>{reporte.cargaTotal}</Td>
                        <Td>{reporte.demoras}</Td>
                        <Td>{reporte.numRemitoRV || "-"}</Td>
                        <Td>{reporte.ToneladasRV || "-"}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
            {!loading && reportes.length <= 0 && (
              <Box py={5}>
                <Heading textAlign={"center"} size={"md"}>
                  No hay reportes de viajes
                </Heading>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"}>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button
              variant="outline"
              border="1px solid #FF8200"
              rightIcon={<MdOutlineGetApp fontSize="20px" />}
              color="brand.naranja"
              onClick={handleDownload}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Descargar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VerViajes;
