import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  FormControl,
  Text,
  Input,
  Heading,
  Select,
  Radio,
  RadioGroup,
  Box,
  Button,
  IconButton,
  Switch,
  useToast,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdArrowDropDown,
  MdFileDownloadDone,
  MdArrowBack,
  MdOutlineAdd,
  MdDelete,
} from "react-icons/md";
import {
  getNotificaciones,
  editarUsuariosNotificaciones,
  getHabilitados,
  getFiltroRol,
  putFiltroRol,
} from "../../helpers/api.helper";
import { getUsuarios } from "../../../usuarios/helpers/api.helper";
import FormModalUsuarios from "../FormModalUsuarios";

const FormCrearEditarNotificacion = () => {
  const id = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [editar, setEditar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [filtroRol, setFiltroRol] = useState([]);
  const [usuariosSeleccionados, setUsuariosSeleccionados] = useState([]);
  const [usuariosOriginales, setUsuariosOriginales] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const removidos = [];

  const initData = async () => {
    try {
      setLoading(true);

      const [usuariosRes, notificacionesRes, habilitadosRes, filtroRolRes] =
        await Promise.all([
          getUsuarios(),
          getNotificaciones(id),
          getHabilitados(id),
          getFiltroRol(),
        ]);

      if (usuariosRes.status === 200) {
        setUsuarios(usuariosRes.data);
      }

      if (notificacionesRes.status === 200) {
        setEditar(notificacionesRes.data[0]);
      }

      if (habilitadosRes.status === 200) {
        setUsuariosSeleccionados(habilitadosRes.data);
      }

      if (filtroRolRes.status === 200) {
        setFiltroRol(filtroRolRes.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error al obtener datos:", error);
      setLoading(false);
    }
  };

  const handleCheckboxChange = (usuario) => {
    const usuarioIndex = usuariosSeleccionados.findIndex(
      (u) => u.idUsuario === usuario.idUsuario
    );

    if (usuarioIndex !== -1) {
      const nuevosUsuariosSeleccionados = [...usuariosSeleccionados];
      nuevosUsuariosSeleccionados.splice(usuarioIndex, 1);
      setUsuariosSeleccionados(nuevosUsuariosSeleccionados);
    } else {
      setUsuariosSeleccionados([...usuariosSeleccionados, usuario]);
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handle = () => {
    let x;

    let filteredRoles = filtroRol.filter(
      (elem) =>
        elem.nombreTipoE === editar.nombreTipoE && elem.filtroOff === true
    );

    if (editar.nombreTipoE === "Registro Evento") {
      filteredRoles = filteredRoles.filter(
        (elem) =>
          elem.criticidadTipoE === editar.criticidadTipoE &&
          elem.claseTipoE === editar.claseTipoE
      );
    }

    x = filteredRoles.map((e) => ({
      rolUsuario: e.rolUsuario,
      telegram: e.telegram === 1,
      email: e.email === 1,
      sandTrucker: e.sandTrucker === 1,
    }));

    return x;
  };

  const closeModal = () => {
    // Restaurar la información original si se cancela
    setUsuariosSeleccionados(usuariosOriginales);
    // Cerrar el modal
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    setUsuariosOriginales([...usuariosSeleccionados]);
  }, [isModalOpen]);

  const initialValues = {
    nombreTipoE: editar.nombreTipoE,
    criticidadTipoE: editar.criticidadTipoE,
    destinatarios: handle(),
  };

  return (
    <Stack
      bg="brand.fondo"
      w="100%"
      h="auto"
      borderRadius={10}
      spacing={7}
      p={3}
    >
      <HStack w="100%" px={3}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate("/administrarNotificaciones")}
          fontSize="24px"
        />
        <Heading fontWeight="medium" py={3}>
          Editar configuración
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            let data = values.destinatarios.map((elemento) => {
              return {
                idTipoE: editar.idTipoE,
                rolUsuario: elemento.rolUsuario,
                sandTrucker: elemento.sandTrucker,
                email: elemento.email,
                telegram: elemento.telegram,
              };
            });

            const usuariosFiltrados = usuariosSeleccionados.filter((usuario) =>
              values.destinatarios.some(
                (rol) => usuario.rolUser === rol.rolUsuario
              )
            );

            const usuariosFiltrados2 = usuariosSeleccionados.filter(
              (usuario) =>
                !values.destinatarios.some(
                  (rol) => usuario.rolUser === rol.rolUsuario
                )
            );

            let putUsuarios = usuariosFiltrados.map((elemento) => {
              return {
                idUsuario: elemento.idUsuario,
                idTipoE: editar.idTipoE,
                filtroOff: false,
              };
            });

            let putUsuarios2 = usuariosFiltrados2.map((elemento) => {
              return {
                idUsuario: elemento.idUsuario,
                idTipoE: editar.idTipoE,
                filtroOff: false,
              };
            });

            let res;
            res = await putFiltroRol({
              roles: data,
            });

            res = await editarUsuariosNotificaciones({
              notifications: putUsuarios,
            });

            res = await editarUsuariosNotificaciones({
              notifications: putUsuarios2,
            });

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Configuración editada correctamente`,
                duration: 3000,
              });
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al editar configuración`,
                duration: 3000,
              });
            }
            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form>
              <Stack w="100%" justifyContent="start" spacing={3}>
                <HStack pb={3} px={3} spacing={5}>
                  <FormControl w="xs">
                    <FormLabel>Evento</FormLabel>
                    <Input
                      icon={<MdArrowDropDown />}
                      w="xs"
                      size="md"
                      name={"nombreTipoE"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly
                      value={values.nombreTipoE}
                    />
                  </FormControl>

                  <FormControl w="xs">
                    {values.nombreTipoE === "Registro Evento" && (
                      <>
                        <FormLabel>Criticidad</FormLabel>
                        <RadioGroup
                          name="criticidadTipoE"
                          value={values.criticidadTipoE}
                        >
                          <Stack direction="row">
                            <Radio
                              isReadOnly
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.criticidadTipoE}
                            >
                              {values.criticidadTipoE}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </>
                    )}
                  </FormControl>
                </HStack>
                <FieldArray name="destinatarios">
                  {({ push, remove }) => (
                    <Stack>
                      <Stack>
                        <HStack pb={3} px={3} spacing={5}>
                          <Stack>
                            {values.destinatarios.map((e, index) => (
                              <HStack py={2} key={index}>
                                <Select
                                  icon={<MdArrowDropDown />}
                                  placeholder="Seleccione un rol"
                                  w="xs"
                                  size="sm"
                                  onChange={handleChange}
                                  name={`destinatarios[${index}].rolUsuario`}
                                  onBlur={handleBlur}
                                  value={values.destinatarios[index].rolUsuario}
                                >
                                  <option value={"cliente"}>Cliente</option>
                                  <option value={"usuarioBrent"}>
                                    Usuario BRENT
                                  </option>
                                  <option value={"coorOp"}>
                                    Coordinador de Operaciones
                                  </option>
                                  <option value={"userOperaciones"}>
                                    Operaciones
                                  </option>

                                  <option value={"userFacturacion"}>
                                    Facturación
                                  </option>
                                  <option value={"userHS"}>
                                    Usuario de HyS
                                  </option>
                                  <option value={"gerencia"}>Gerencia</option>
                                  <option value={"mantenimiento"}>
                                    Mantenimiento
                                  </option>
                                </Select>
                                <HStack>
                                  <Switch
                                    onChange={handleChange}
                                    isChecked={
                                      values.destinatarios[index].email
                                    }
                                    name={`destinatarios[${index}].email`}
                                  />
                                  <Text>Email</Text>
                                </HStack>
                                <HStack>
                                  <Switch
                                    onChange={handleChange}
                                    isChecked={
                                      values.destinatarios[index].telegram
                                    }
                                    name={`destinatarios[${index}].telegram`}
                                  />
                                  <Text>Telegram</Text>
                                </HStack>
                                <HStack>
                                  <Switch
                                    onChange={handleChange}
                                    isChecked={
                                      values.destinatarios[index].sandTrucker
                                    }
                                    name={`destinatarios[${index}].sandTrucker`}
                                  />
                                  <Text>SandTracker</Text>
                                </HStack>
                                <Tooltip label="Remover rol">
                                  <IconButton
                                    variant="link"
                                    size="lg"
                                    icon={<MdDelete size={18} color={"red"} />}
                                    onClick={() => {
                                      remove(index);
                                      removidos.push(e);
                                    }}
                                    isDisabled={
                                      values.destinatarios.length <= 1
                                    }
                                  />
                                </Tooltip>
                                <Tooltip label="Lista de usuarios">
                                  <Button
                                    variant={"link"}
                                    onClick={() => openModal(e)}
                                  >
                                    Lista
                                  </Button>
                                </Tooltip>

                                {selectedItem && (
                                  <FormModalUsuarios
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    usuarios={usuarios}
                                    usuariosSeleccionados={
                                      usuariosSeleccionados
                                    }
                                    handleCheckboxChange={handleCheckboxChange}
                                    closeModal={closeModal}
                                    setIsModalOpen={setIsModalOpen}
                                    selectedItem={selectedItem}
                                  />
                                )}
                              </HStack>
                            ))}
                          </Stack>
                        </HStack>
                      </Stack>
                      <Box px={5}>
                        <Button
                          placeSelf={"start"}
                          fontSize="12px"
                          variant="link"
                          size="lg"
                          leftIcon={
                            <MdOutlineAdd size={18} color={"#FF8200"} />
                          }
                          onClick={() => {
                            push({
                              rolUsuario: "",
                              whatsapp: true,
                              email: true,
                              sandTrucker: true,
                            });
                          }}
                        >
                          Agregar Rol
                        </Button>
                      </Box>
                    </Stack>
                  )}
                </FieldArray>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formAutoelevador"
                  isDisabled={loading}
                  color="white"
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarNotificacion;
