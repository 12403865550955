import React, { useState, useEffect, useRef } from "react";
import {
  HStack,
  VStack,
  useToast,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  Heading,
  Radio,
  Box,
  Link,
  Img,
  Checkbox,
  Center,
  Text,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineLock,
  MdPersonOutline,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdLogin,
} from "react-icons/md";
import {
  postLogin,
  loginRequest,
  loginValidateAD,
  clearCachedTokens,
} from "./helpers/api.helper";
import { loginSuccess } from "../../redux/actions/action.user";
import Fondo from "../../assets/fondoGrande.png";
import LogoBrent from "../../assets/Brent-Marca-con-bajada-CMYK.png";
import LogoMurchinson from "../../assets/Una Empresa.svg";
import { ReactComponent as LineaCurva } from "../../assets/iconos/Linea-Curva.svg";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [recordar, setRecordar] = useState(false);
  const [terminosYCondiciones, setTerminosYCondiciones] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { instance } = useMsal();

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!username || !password) {
      setLoading(false);
      return toast({
        description: "Parametros insuficientes",
        status: "error",
        duration: 3000,
      });
    }
    if (!terminosYCondiciones) {
      setLoading(false);
      return toast({
        description: "Se deben aceptar los terminos y condiciones",
        status: "error",
        duration: 3000,
      });
    }

    let iniciandoSesion = toast({
      title: "Iniciando Sesión",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    let res = await postLogin(username, password);

    try {
      if (res.data.success) {
        dispatch(loginSuccess(res.data.data.user));
        sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
        sessionStorage.setItem("token", res.data.data.token);
        if (recordar) {
          sessionStorage.setItem("password", res.data.data.user.password);
        } else {
          sessionStorage.removeItem("password");
        }
      } else if (res.data === 403) {
        setLoading(false);
        toast({
          status: "error",
          description: "Usuario inactivo",
          isClosable: true,
          title: "Error",
          duration: 3000,
        });
      } else {
        setLoading(false);
        toast({
          status: "error",
          description: res.data.error.message,
          isClosable: true,
          title: "Error",
          duration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      return toast({
        title: "Error al iniciar sesión",
        status: "error",
        description: "Error de conexión al servidor",
        duration: 3000,
        isClosable: true,
      });
    }

    toast.close(iniciandoSesion);
    setLoading(false);
  };

  if (username === sessionStorage.getItem("username")) {
    username.valueOf = sessionStorage.getItem("username");
    password.valueOf = sessionStorage.getItem("password");
    recordar.checked = true;
    terminosYCondiciones.checked = true;
  }
  useEffect(() => {
    const storedPassword = sessionStorage.getItem("password");
    if (recordar && storedPassword) {
      setPassword(storedPassword);
    }
  }, [recordar]);

  const handleFocus = () => {
    setFocus(true);

    initialRef.current && initialRef.current.focus();
  };

  const handleLoginValidateADResult = (res) => {
    try {
      if (res.data.success) {
        dispatch(loginSuccess(res.data.data.user));
        sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
        sessionStorage.setItem("token", res.data.data.token);
        if (recordar) {
          sessionStorage.setItem("password", res.data.data.user.password);
        } else {
          sessionStorage.removeItem("password");
        }
      } else {
        setLoading(false);
        toast({
          status: "error",
          description: res.data.error.message,
          isClosable: true,
          title: "Error",
          duration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      return toast({
        title: "Error al iniciar sesión",
        status: "error",
        description: "Error de conexión al servidor",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack
      w="100vw"
      h="100vh"
      bgImage={Fondo}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      fontFamily="body"
      overflowY={"hidden"}
    >
      <HStack w="100%" justifyContent={"end"}>
        <IconButton
          variant={"link"}
          color="brand.naranja"
          _hover={{ color: "white" }}
          icon={<MdLogin />}
          onClick={() => {
            onOpen();
            handleFocus();
          }}
          size="lg"
        />
      </HStack>
      <HStack w="100%" pl={16} pt={10}>
        <Center
          h={["xs", "sm", "md", "md", "2xl"]}
          w={"xl"}
          bgColor="white"
          borderRadius="30px"
          alignSelf="start"
          zIndex={100}
        >
          <Stack w="100%" h="100%">
            <Box
              placeSelf="start"
              pt={10}
              pl={10}
              alignSelf="start"
              boxSize={"45%"}
            >
              <Img src={LogoBrent} alt="" />
            </Box>
            <Stack
              h="100%"
              pt={7}
              textAlign="left"
              w="65%"
              pl={10}
              spacing={-3}
              placeSelf="start"
              alignSelf="start"
            >
              <Heading fontWeight="bold" textColor={"#FF8200"}>
                SandPro Navigator
              </Heading>
              <br />
              <Text fontWeight="normal" textColor={"#9EAAB8"} fontSize={"26px"}>
                Portal de seguimiento de operación de última milla
              </Text>
            </Stack>

            <Stack w="100%" pl={10}>
              <Checkbox
                borderColor="blackAlpha.500"
                isChecked={terminosYCondiciones}
                onChange={() => setTerminosYCondiciones(!terminosYCondiciones)}
                colorScheme={"orange"}
                fontSize={"10px"}
                size={"sm"}
              >
                Acepto los{" "}
                <Link href="#" textColor="brand.azul_text">
                  Términos & Condiciones
                </Link>
              </Checkbox>
              <HStack justifyContent={"space-between"}>
                <Button
                  bg="brand.azul_fondo"
                  color="white"
                  _hover={{ opacity: 0.7 }}
                  onClick={async () => {
                    try {
                      await clearCachedTokens(instance); //instance["browserStorage"].clear();
                      console.log(
                        "[msal] Almacenamiento del navegador limpiado exitosamente"
                      );
                    } catch (error) {
                      console.error(
                        "[msal] Error al limpiar el almacenamiento del navegador",
                        error
                      );
                    }
                    const ad = await instance.loginPopup(loginRequest);
                    const res = await loginValidateAD(ad);
                    handleLoginValidateADResult(res);
                  }}
                  isDisabled={!terminosYCondiciones}
                  fontSize={["14px", "14px", "16px", "18px", "20px"]}
                  w={"150px"}
                >
                  Ingresar
                </Button>

                <Image src={LogoMurchinson} alt="" w="300px" />
              </HStack>
            </Stack>
          </Stack>
        </Center>

        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setTerminosYCondiciones(false);
          }}
          bg="white"
          p={3}
          borderRadius="10px"
          initialFocusRef={initialRef}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading
                textColor="brand.azul_text"
                fontWeight="medium"
                textAlign="left"
                size="lg"
              >
                ¡Bienvenido!
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form id="formLogin">
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<MdPersonOutline />}
                    />
                    <Input
                      name="username"
                      value={username}
                      type="text"
                      placeholder="Usuario"
                      onChange={(e) => setUsername(e.target.value)}
                      _placeholder={{ color: "black.300" }}
                      border="1px solid black"
                      disabled={loading}
                      ref={initialRef}
                    />
                  </InputGroup>
                </FormControl>
                <br />
                <FormControl>
                  <InputGroup size="md">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<MdOutlineLock />}
                    />
                    <Input
                      border="1px solid black"
                      value={password}
                      name="password"
                      placeholder="Contraseña"
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={loading}
                      type={show ? "text" : "password"}
                      _placeholder={{ color: "black.300" }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        pl={2}
                        size="sm"
                        variant="unstyled"
                        onClick={() => setShow(!show)}
                      >
                        {show ? (
                          <MdOutlineVisibility />
                        ) : (
                          <MdOutlineVisibilityOff />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <br />
                <VStack w="100%">
                  <Radio
                    size="sm"
                    value={recordar}
                    onChange={() => setRecordar(!recordar)}
                    border="1px solid black"
                  >
                    Recordar mi usuario y contraseña
                  </Radio>
                </VStack>
              </form>
              <br />
              <Button
                type="submit"
                form="formLogin"
                onClick={handleLogin}
                isLoading={loading}
                bg="brand.naranja"
                color="brand.fondos_primarios"
                w="100%"
                fontWeight="medium"
              >
                Iniciar sesión
              </Button>
            </ModalBody>

            <ModalFooter>
              <Link
                onClick={() => navigate("/recuperarDatos")}
                textColor="brand.azul_text"
              >
                ¿Olvidaste tus datos?
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
      <Stack position={"absolute"} top={450} right={0}>
        <LineaCurva />
      </Stack>
      <HStack w="100%" justifyContent="end" pt={16}>
        <Text
          fontSize={["0.5rem", "0.4rem", "0.6rem", "0.7rem", "1.5rem"]}
          color={"rgba(255, 255, 255, .7)"}
          pb={7}
          pr={5}
        >
          BRENT © 2023 - Todos los derechos reservados.
        </Text>
      </HStack>
    </Stack>
  );
};

export default Login;
