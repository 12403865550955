import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Card,
  CardBody,
  CardFooter,
  Textarea,
} from "@chakra-ui/react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

const CuartoPasoEtapa = (props) => {
  const {
    planificacion,
    setActiveStep,
    activeStep,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    text,
    setText,
  } = props;
  const [autoelevador, setAutoelevador] = useState({ id: "", nombre: "" });
  const [sander, setSander] = useState({ id: "", nombre: "" });
  const [pozo, setPozo] = useState([]);
  const handleCaracteres = (event) => {
    const { value } = event.target;
    setText(value);
  };
  const charCount = text.length;

  const handleParse = () => {
    let a = values.idAutoE;
    let s = values.idSander;
    let p = values.idPozoEtapa;
    let x;
    let y;
    let z;

    z = planificacion.pozos.find((pozo) => pozo.idPozo === parseInt(p));
    setPozo(z);

    if (
      planificacion.autoelevador1 &&
      parseInt(a) === planificacion.autoelevador1.idAutoE
    ) {
      x = planificacion.autoelevador1;
      setAutoelevador({ id: x.idAutoE, nombre: x.nombreAutoE });
    } else if (
      planificacion.autoelevador2 &&
      parseInt(a) === planificacion.autoelevador2.idAutoE
    ) {
      x = planificacion.autoelevador2;
      setAutoelevador({ id: x.idAutoE, nombre: x.nombreAutoE });
    }

    if (
      planificacion.sanderPrincipal &&
      parseInt(s) === planificacion.sanderPrincipal.idSander
    ) {
      y = planificacion.sanderPrincipal;

      setSander({ id: y.idSander, nombre: y.codigoSander });
    } else if (
      planificacion.sanderBackup &&
      parseInt(s) === planificacion.sanderBackup.idSander
    ) {
      y = planificacion.sanderBackup;
      setSander({ id: y.idSander, nombre: y.codigoSander });
    }
  };

  useEffect(() => {
    handleParse();
  }, []);

  return (
    <Stack w="100%" pb={2}>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">ID Pozo</FormLabel>
          <Input value={pozo.nombrePozo} bg="brand.gris_medio" readOnly />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Nro. Etapa (pozo)</FormLabel>
          <Input
            w="2xs"
            value={values.numPozoEtapaSC}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Nro. Etapa (total)</FormLabel>
          <Input
            w="2xs"
            value={values.numTotalEtapaSC}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Fecha y Hora de Inicio</FormLabel>
          <Input
            w="2xs"
            value={values.fechaInicioEtapaSC}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Fecha y Hora de Final</FormLabel>
          <Input
            w="2xs"
            type={"datetime-local"}
            name="fechaFinalEtapaSC"
            value={values.fechaFinalEtapaSC}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl w="2xs">
          {planificacion.dosificadora && (
            <>
              <FormLabel fontWeight="semibold">Wetsand</FormLabel>
              <Input
                value={values.etapaWeTSanD === true ? "si" : "no"}
                textTransform="uppercase"
                bg="brand.gris_medio"
                readOnly
              />
            </>
          )}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Supervisor</FormLabel>
          <Input
            w="2xs"
            value={values.supervisor}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Operador</FormLabel>
          <Input
            w="2xs"
            value={values.operador1}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Operador</FormLabel>
          <Input
            w="2xs"
            value={values.operador2}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={1}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Maquinista</FormLabel>
          <Input
            w="2xs"
            value={values.maquinista}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Sander</FormLabel>
          <Input w="2xs" value={sander.nombre} bg="brand.gris_medio" readOnly />
        </FormControl>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Autoelevador</FormLabel>
          <Input
            w="2xs"
            value={autoelevador.nombre}
            bg="brand.gris_medio"
            readOnly
          />
        </FormControl>
      </HStack>

      <HStack w="100%">
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          variant="outline"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          _hover={{ bg: "brand.naranja", color: "white" }}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Atras
        </Button>
        <Stack w="100%" px={12}>
          <Card variant="unstyled" bg="brand.fondos_secundarios">
            <CardBody>
              <Textarea
                placeholder="Descripcion"
                border="1px solid #FF8200"
                name="descripcionAreaHidrocarburifera"
                onChange={handleCaracteres}
                value={text}
                maxLength="250"
                resize="none"
              />
            </CardBody>
            <CardFooter placeSelf="end" color="brand.gris_medio" pr={2}>
              {charCount}/250
            </CardFooter>
          </Card>
        </Stack>
        <Box px={5}>
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            variant="solid"
            form="FormularioEtapa"
            _hover={{ bg: "white", color: "brand.naranja" }}
            onClick={handleSubmit}
          >
            Finalizar etapa
          </Button>
        </Box>
      </HStack>
    </Stack>
  );
};

export default CuartoPasoEtapa;
