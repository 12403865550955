import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Input,
} from "@chakra-ui/react";
import {
  MdOutlineImportExport,
  MdOutlineCheckCircleOutline,
} from "react-icons/md";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { sortData } from "../../../utils/sortData";
import moment from "moment/moment";
import AgregarNroremito from "../../operaciones/components/curso/reporte/AgregarNroremito";
import AgregarRemito from "../../operaciones/components/curso/reporte/AgregarRemito";
import AgregarToneladas from "../../operaciones/components/curso/reporte/AgregarToneladas";

const DisenoTabla = (props) => {
  const { user, initData, reportesViajes, setReportesViajes } = props;
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [editable, setEditable] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [showIcon, setShowIcon] = useState({
    display: false,
    element: "",
  });

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const handleColorEstado = (e) => {
    switch (e) {
      case "Finalizado":
        return "brand.verde";
      case "Caducado":
        return "brand.rojo";
      case "En curso":
        return "#fbb034";
      case "cancelado":
        return "brand.gris_medio";
      default:
        return "-";
    }
  };

  const handleTextoEstado = (e) => {
    switch (e) {
      case "En curso":
        return "En curso";
      case "Finalizado":
        return "Finalizado";
      case "cancelado":
        return "Cancelado";
      case "Caducado":
        return "Caducado";
      default:
        return "-";
    }
  };

  const handleDobleClick = () => {
    onOpen();
  };

  const handleSorteable = (element, nombre) => {
    return (
      <Th
        _hover={{ cursor: "pointer" }}
        onMouseEnter={() => setShowIcon({ display: true, element: element })}
        onMouseLeave={() => setShowIcon({ display: false, element: "" })}
      >
        <span
          className="sortable"
          onClick={() => {
            const { sortedData, direction } = sortData(element, reportesViajes);
            setSortField(element);
            setSortDirection(direction);
            setReportesViajes(sortedData);
          }}
        >
          {nombre}
          {sortDirection === "asc" && sortField === element && <ArrowUpIcon />}
          {sortDirection === "des" && sortField === element && (
            <ArrowDownIcon />
          )}
        </span>
        {showIcon && element === showIcon.element && (
          <span style={{ display: "inline-block" }}>
            <MdOutlineImportExport />
          </span>
        )}
      </Th>
    );
  };

  return (
    <>
      <TableContainer h={"lg"} overflowY={"scroll"} id="TBody">
        <Table size="sm" variant="simple">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "#F3F6F9",
              opacity: 1,
            }}
          >
            <Tr>
              {handleSorteable("idReporteViaje", "ID viaje")}
              {handleSorteable("statusRV", "Estado")}
              {handleSorteable("status2RV", "Locación")}
              {handleSorteable("padDestino", "PAD destino")}
              {handleSorteable("transporteRV", "Transporte")}
              {handleSorteable("camionRV", "Camion")}
              {handleSorteable("nombreCamion", "Interno")}
              {handleSorteable("sandCube1", "SCs asociados")}
              {handleSorteable("sandCube2", "SCs asociados")}
              {handleSorteable("plantaCarga", "Planta Carga")}
              {handleSorteable("tsPozoOut", "TS Pozo out")}
              {handleSorteable("tsPlantaIn", "TS Planta in")}
              {handleSorteable("tsPlantaOut", "TS Planta out")}
              {handleSorteable("tsPozoIn", "TS Pozo in")}
              {handleSorteable("eta", "ETA")}
              {handleSorteable("standByPlanta", "Stand en Planta (mins.)")}
              {handleSorteable("standByPozo", "Standby en Pozo (mins.)")}
              {handleSorteable("tiempoVuelta", "Tiempo Vuelta (mins.)")}
              {handleSorteable("kms", "KMS")}
              {handleSorteable("fechaRV", "Fecha reporte")}
              {handleSorteable("cargaTotal", "Carga Total")}
              {handleSorteable("demoras", "Demoras (mins.)")}
              {handleSorteable("numRemitoRV", "Nro. de Remito")}
              <Th>Imagen Remito</Th>
              {handleSorteable("ToneladasRV", "Toneladas")}
            </Tr>
          </Thead>

          <Tbody>
            {reportesViajes.map((reporte) => (
              <Tr
                key={reporte.idReporteViaje}
                onDoubleClick={() => {
                  setEditable(reporte);
                  handleDobleClick();
                }}
                cursor={
                  (user.idRol === "coorOp" || user.idRol === "admin") &&
                  ((reporte.statusRV === "En curso" &&
                    reporte.tsPlantaOut &&
                    !reporte.tsPozoIn) ||
                    (reporte.statusRV === "Finalizado" &&
                      reporte.camionFinal)) &&
                  "pointer"
                }
              >
                <Td>{reporte.idReporteViaje}</Td>
                <Td color={handleColorEstado(reporte.statusRV)}>
                  {handleTextoEstado(reporte.statusRV)}
                </Td>
                <Td>{reporte.status2RV}</Td>
                <Td>{reporte.padDestino}</Td>
                <Td>{reporte.transporteRV}</Td>
                <Td>{reporte.camionRV}</Td>
                <Td>{reporte.nombreCamion}</Td>
                <Td>{reporte.sandCube1}</Td>
                <Td>{reporte.sandCube2}</Td>
                <Td>{reporte.plantaCarga}</Td>
                <Td>
                  {reporte.tsPozoOut &&
                    moment.utc(reporte.tsPozoOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaIn &&
                    moment.utc(reporte.tsPlantaIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPlantaOut &&
                    moment.utc(reporte.tsPlantaOut).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.tsPozoIn &&
                    moment.utc(reporte.tsPozoIn).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>
                  {reporte.eta &&
                    moment.utc(reporte.eta).format("DD/MM/YYYY HH:mm")}
                </Td>
                <Td>{reporte.standByPlanta}</Td>
                <Td>{reporte.standByPozo}</Td>
                <Td>{reporte.tiempoVuelta}</Td>
                <Td>{reporte.kms}</Td>
                <Td>
                  {reporte.fechaRV &&
                    moment.utc(reporte.fechaRV).format("DD/MM/YYYY")}
                </Td>
                <Td>{reporte.cargaTotal}</Td>
                <Td>{reporte.demoras}</Td>
                {!reporte.numRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarNroremito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td>{reporte.numRemitoRV || "-"}</Td>
                )}
                {!reporte.imgRemitoRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarRemito
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : reporte.imgRemitoRV ? (
                  <Td>
                    <Icon
                      as={MdOutlineCheckCircleOutline}
                      color="white"
                      bg="green"
                      borderRadius="50%"
                    />
                  </Td>
                ) : (
                  <Td>-</Td>
                )}
                {!reporte.ToneladasRV &&
                (user.idRol === "admin" || user.idRol === "coorOp") ? (
                  <Td>
                    <AgregarToneladas
                      reporte={reporte}
                      initData={initData}
                      reportesViajes={reportesViajes}
                      setReportesViajes={setReportesViajes}
                    />
                  </Td>
                ) : (
                  <Td>{reporte.ToneladasRV || "-"}</Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {editable && (
        <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isLazy>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Reporte N° {editable.idReporteViaje}</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody border={"1px solid gray"} borderRadius={"10px"} mx={3}>
              <Stack>
                <FormControl>
                  <FormLabel>Camion que inicio el viaje</FormLabel>
                  <Text>{editable.dominio}</Text>
                </FormControl>

                <FormControl>
                  <FormLabel>Camion que lo finalizó</FormLabel>
                  <Select></Select>
                </FormControl>

                <FormControl>
                  <FormLabel>
                    <Input type="datetime-local" />
                  </FormLabel>
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                colorScheme="red"
                mr={3}
                onClose={onClose}
              >
                Close
              </Button>
              <Button bg={"brand.naranja"} color={"white"}>
                Editar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default DisenoTabla;
