import React, { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Heading,
  Text,
  Divider,
  Box,
  Badge,
  Icon,
  Stack,
  Progress,
  Table,
  Thead,
  Tbody,
  Stat,
  Tr,
  Th,
  Td,
  StatArrow,
  StatNumber,
  TableContainer,
  IconButton,
  Select,
  useToast,
} from "@chakra-ui/react";
import {
  CartesianGrid,
  BarChart,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdArrowBack,
  MdInsertInvitation,
  MdOutlineAirportShuttle,
  MdOutlineTimer,
} from "react-icons/md";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { RiErrorWarningFill, RiCheckboxCircleFill } from "react-icons/ri";
import { getPlanificaciones } from "../../operaciones/helpers/api.helper";
import { getGeocercas } from "../../geocercas/helpers/api.helper";
import { getReportesViajes } from "../../operaciones/helpers/api.helper";
import { ReactComponent as Sandcube } from "../../../assets/iconos/Sand.svg";
import moment from "moment";

const DetalleOperacion = () => {
  const [operacion, setOperacion] = useState([]);
  const [operacionSeleccionada, setOperacionSeleccionada] = useState();
  const [geocercas, setGeocercas] = useState([]);
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  let llenosPozo = 0;
  let vaciosPozo = 0;
  let transitoPozo = 0;
  const navigate = useNavigate();
  const { padLocacion } = useParams();
  const toast = useToast();
  let today = moment().format("YYYY-MM-DDTHH:mm:ss");

  const initData = async () => {
    let res;
    res = await getPlanificaciones({ padLocacion });

    if (res.status === 200) {
      setOperacion(res.data[0]);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getGeocercas();
    if (res.status === 200) {
      setGeocercas(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getReportesViajes({ padDestino: padLocacion });
    if (res.status === 200) {
      setReportes(res.data.reportes);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };
  const handleDiasOperados = (fecha) => {
    let f = moment(fecha);

    let diasOperados = moment(today).diff(f, "days");

    return diasOperados;
  };
  const handleDiasRestantes = (etapasTotales, fecha) => {
    let f = moment(fecha);

    let diasOperados = moment(today).diff(f, "days");
    let promedio =
      (handleEtapasAyer() + handleEtapasHoy() + handleEtapasMax()) /
      diasOperados;
    let etapasRestantes = etapasTotales - operacion.etapasSC.length;

    let diasRestantes = etapasRestantes / promedio;

    return diasRestantes.toFixed(0);
  };
  const handlePromedio = (fecha) => {
    let f = moment(fecha);

    let diasOperados = moment(today).diff(f, "days");
    let promedio =
      (handleEtapasAyer() + handleEtapasHoy() + handleEtapasMax()) /
      diasOperados;

    return promedio.toFixed(2);
  };
  const fusionarEtapasConCantidad = () => {
    const fechasGeneradas = [];
    const cantidadesPorFecha = {};
    const fechaActual = moment();
    let fechaActualTemp = moment(operacion.fechaInicio);

    if (operacion.etapasSC) {
      operacion.etapasSC
        .filter((e) => e.eliminado === false)
        .forEach((etapa) => {
          const fechaFinalEtapaSC = moment(etapa.fechaFinalEtapaSC);

          if (fechaFinalEtapaSC.isSameOrAfter(fechaActualTemp, "day")) {
            const fechaFinalEtapaSCStr = fechaFinalEtapaSC.format("DD-MM");

            if (!cantidadesPorFecha[fechaFinalEtapaSCStr]) {
              cantidadesPorFecha[fechaFinalEtapaSCStr] = 0;
            }

            cantidadesPorFecha[fechaFinalEtapaSCStr] += 1;
          }
        });
    }

    while (fechaActualTemp.isSameOrBefore(fechaActual, "day")) {
      const diaMes = {
        dia: fechaActualTemp.date(),
        mes: fechaActualTemp.month() + 1,
        fecha: fechaActualTemp.toDate(),
      };

      const fecha = fechaActualTemp.format("DD-MM");
      const fechaII = fechaActualTemp.format("DD-MM");
      const cantidad = cantidadesPorFecha[fecha] || 0;

      fechasGeneradas.push({ ...diaMes, fechaII, cantidad });

      fechaActualTemp.add(1, "day");
    }

    return fechasGeneradas;
  };
  const handlePorcetajeCompleto = (completas, total) => {
    let porcentaje = (completas.length / total) * 100;
    return porcentaje;
  };
  const handleEtapasHoy = () => {
    let b = moment(today).format("DD-MM-YYYY");

    if (operacion.etapasSC) {
      let etapasHoy = operacion.etapasSC.filter(
        (element) =>
          moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
      );

      return etapasHoy.length;
    } else {
      return 0;
    }
  };
  const handleEtapasAyer = () => {
    let ayer = moment().subtract(1, "days");
    let b = moment(ayer).format("DD-MM-YYYY");

    if (operacion.etapasSCion) {
      let etapasAyer = operacion.etapasSC.filter(
        (element) =>
          moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
      );

      return etapasAyer.length;
    } else {
      return 0;
    }
  };
  const handleEtapasMax = () => {
    const fechaFinalCount = {};

    if (operacion.etapasSC) {
      operacion.etapasSC.forEach((etapa) => {
        const fechaFinal = moment(etapa.fechaFinalEtapaSC).format("DD-MM-YYYY");

        if (fechaFinalCount[fechaFinal]) {
          fechaFinalCount[fechaFinal]++;
        } else {
          fechaFinalCount[fechaFinal] = 1;
        }
      });
    }

    let cantidadMasComun = 0;

    for (const fechaFinal in fechaFinalCount) {
      if (fechaFinalCount[fechaFinal] > cantidadMasComun) {
        cantidadMasComun = fechaFinalCount[fechaFinal];
      }
    }

    return cantidadMasComun;
  };
  const handleTnsOperada = (elemento) => {
    let sumaDeLongitudes = 0;
    if (elemento.etapasSC) {
      for (let i = 0; i < elemento.etapasSC.length; i++) {
        if (
          elemento.etapasSC[i].sandCubes &&
          elemento.etapasSC[i].sandCubes.length > 0
        ) {
          sumaDeLongitudes += elemento.etapasSC[i].sandCubes.length;
        }
      }

      return sumaDeLongitudes * 13.5;
    } else {
      return 0;
    }
  };
  const handleSandcubes = (event) => {
    if (operacion.scAsociados && event === "llenosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          llenosPozo++;
        }
      });
      return llenosPozo;
    } else if (operacion.scAsociados && event === "vaciosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Vacio"
        ) {
          vaciosPozo++;
        }
      });
      return vaciosPozo;
    } else if (operacion.scAsociados && event === "transitoPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN TRÁNSITO A POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          transitoPozo++;
        }
      });
      return transitoPozo;
    } else {
      return 0;
    }
  };
  const handleUltimoViajeCamion = () => {
    const viajesPorCamion = reportes.reduce((acumulador, viaje) => {
      acumulador[viaje.camionRV] = acumulador[viaje.camionRV] || [];
      acumulador[viaje.camionRV].push(viaje);
      return acumulador;
    }, {});
    const ultimosReportesPorCamion = [];
    for (const camion in viajesPorCamion) {
      ultimosReportesPorCamion.push(viajesPorCamion[camion][0]);
    }

    const reportesMenosDe8Horas = [];
    ultimosReportesPorCamion.forEach((e) => {
      let a = moment.utc(e.tsPozoOut).format("YYYY-MM-DDTHH:mm:ss");
      const diffHoras = moment(today).diff(a, "hours");
      if (diffHoras < 8) {
        reportesMenosDe8Horas.push(e);
      }
    });

    return reportesMenosDe8Horas.length;
  };

  const handleDetallePlanta = () => {
    let reportesPlanta,
      standByPlantaHoy,
      ttPlantaPozoHoy,
      ttPozoPlantaHoy,
      vueltaCompletaHoy,
      VDCFijada,
      VDCHoy,
      reportesPlantaAvg,
      standByPlantaAvg,
      ttPlantaPozoAvg,
      ttPozoPlantaAvg,
      vueltaCompletaAvg,
      tonsTransportadas,
      tonsTransportadasAvg,
      porcentajeHoyPlanta,
      VDCAvg;

    const seleccionada = operacion.detallePlantas.filter(
      (e) => e.idPlanta === parseInt(operacionSeleccionada)
    );

    const handleActivoCamion = (value) => {
      const estadoMapping = {
        60: 1,
        120: 2,
        180: 3,
      };
      const estadoText = estadoMapping[value] || 0;
      return (24 - estadoText) * 60;
    };

    if (seleccionada.length > 0) {
      reportesPlanta = reportes.filter(
        (e) => e.IdPlanta === seleccionada[0].idPlanta
      );

      reportesPlantaAvg = reportesPlanta.filter(
        (e) => e.statusRV === "Finalizado"
      );

      reportesPlanta = reportesPlanta
        .filter((e) => e.statusRV === "Finalizado")
        .filter(
          (e) =>
            moment.utc(e.fechaRV).format("YYYY-MM-DD") ===
            moment(today).format("YYYY-MM-DD")
        );

      let reportesPorCamion = {};
      let reportesPorCamionAvg = {};
      let totalMinutosPorCamion = {};

      reportesPlanta.forEach(function (report) {
        let camion = report.camionRV;
        if (!reportesPorCamion[camion]) {
          reportesPorCamion[camion] = [];
        }
        reportesPorCamion[camion].push(report);
      });

      reportesPlantaAvg.forEach(function (report) {
        let camion = report.camionRV;
        if (!reportesPorCamionAvg[camion]) {
          reportesPorCamionAvg[camion] = [];
        }
        reportesPorCamionAvg[camion].push(report);
      });

      for (let camion in reportesPorCamion) {
        if (reportesPorCamion.hasOwnProperty(camion)) {
          totalMinutosPorCamion[camion] = 0;
          let reportesDelCamion = reportesPorCamion[camion];

          for (let i = 0; i < reportesDelCamion.length - 1; i++) {
            let tsPozoInActual = moment(reportesDelCamion[i].tsPozoOut);
            let tsPozoOutSiguiente = moment(reportesDelCamion[i + 1].tsPozoIn);
            let diferenciaMinutos = tsPozoOutSiguiente.diff(
              tsPozoInActual,
              "minutes"
            );
            totalMinutosPorCamion[camion] += diferenciaMinutos;
          }
        }
      }

      let s = reportesPlanta.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPlanta;
      }, 0);

      let sAvg = reportesPlantaAvg.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPlanta;
      }, 0);

      let vueltaCompletaHoyTemp = reportesPlanta.reduce(
        (acumulador, elemento) => {
          return acumulador + elemento.tiempoVuelta;
        },
        0
      );

      let vueltaCompletaAvgTemp = reportesPlantaAvg.reduce(
        (acumulador, elemento) => {
          return acumulador + elemento.tiempoVuelta;
        },
        0
      );

      standByPlantaHoy = s / reportesPlanta.length || 0;
      standByPlantaAvg = sAvg / reportesPlantaAvg.length || 0;
      vueltaCompletaHoy = vueltaCompletaHoyTemp / reportesPlanta.length || 0;
      vueltaCompletaAvg = vueltaCompletaAvgTemp / reportesPlantaAvg.length || 0;

      //----------------------------------------------------------------------
      let ttPlantaPozoHoyII = reportesPlanta.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPlantaPozo,
        0
      );

      ttPlantaPozoHoy = ttPlantaPozoHoyII / reportesPlanta.length || 0;
      //-----------------------------------------------------------------------
      let ttPozoPlantaHoyII = reportesPlanta.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPozoPlanta,
        0
      );
      ttPozoPlantaHoy = ttPozoPlantaHoyII / reportesPlanta.length || 0;
      //-----------------------------------------------------------------------
      let ttPozoPlantaAvgII = reportesPlantaAvg.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPozoPlanta,
        0
      );
      ttPozoPlantaAvg = ttPozoPlantaAvgII / reportesPlantaAvg.length || 0;
      //-----------------------------------------------------------------------
      let ttPlantaPozoAvgII = reportesPlantaAvg.reduce(
        (total, objeto) => total + objeto.tiempoSalidaPlantaPozo,
        0
      );

      ttPlantaPozoAvg = ttPlantaPozoAvgII / reportesPlantaAvg.length || 0;
      //-----------------------------------------------------------------------

      porcentajeHoyPlanta =
        (porcentajeHoyPlanta * (reportesPlanta.length * 27)) / 100 || 0;

      tonsTransportadas = reportesPlanta.reduce(
        (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
        0
      );

      tonsTransportadasAvg = reportesPlantaAvg.reduce((total, objeto) => {
        const valorPropiedad = objeto["cargaTotal"];
        return valorPropiedad !== null
          ? total + parseFloat(valorPropiedad)
          : total;
      }, 0);

      VDCFijada =
        (seleccionada[0].targetVueltaCompleta + seleccionada[0].targetStandBy) /
        handleActivoCamion(parseInt(seleccionada[0].standByCamion));

      //-----------------------------------------------------------------------
      let tempX = vueltaCompletaHoyTemp + parseInt(handleStandByPozo("hoy"));
      let tempW = tempX * reportesPlanta.length;

      VDCHoy = tempX / tempW || 0;

      //-----------------------------------------------------------------------
      let tempZ = vueltaCompletaAvgTemp + parseInt(handleStandByPozo("total"));
      let tempY = tempZ * reportesPlantaAvg.length;

      VDCAvg = tempZ / tempY || 0;
    }
    //-------------------------------------------------------------------------

    const plantaSeleccionada = geocercas.filter(
      (e) => e.idPlanta === parseInt(operacionSeleccionada)
    );
    function sumarKilometros(event) {
      if (event === "hoy") {
        let x = reportesPlanta.find((e) => e.kms !== null);
        if (x && !isNaN(x.kms / ((ttPozoPlantaHoy + ttPlantaPozoHoy) / 60))) {
          return (x.kms / ((ttPozoPlantaHoy + ttPlantaPozoHoy) / 60)).toFixed(
            2
          );
        } else {
          return 0;
        }
      } else if (event === "total") {
        let x = reportesPlantaAvg.find((e) => e.kms !== null);
        if (x && !isNaN(x.kms / ((ttPozoPlantaAvg + ttPlantaPozoAvg) / 60))) {
          return (x.kms / ((ttPozoPlantaAvg + ttPlantaPozoAvg) / 60)).toFixed(
            2
          );
        } else {
          return 0;
        }
      } else if (event === "target") {
        let x = reportesPlantaAvg.find((e) => e.kms !== null);
        if (
          x &&
          !isNaN(
            x.kms /
              ((seleccionada[0].targetIdaPozoPlanta +
                seleccionada[0].targetVueltaPozoPlanta) /
                60)
          )
        ) {
          return (
            x.kms /
            ((seleccionada[0].targetIdaPozoPlanta +
              seleccionada[0].targetVueltaPozoPlanta) /
              60)
          ).toFixed(2);
        } else {
          return 0;
        }
      }
    }

    if (seleccionada.length > 0) {
      return (
        <Stack w={"100%"}>
          <HStack px={5}>
            <Text fontSize="30px" fontWeight="bold">
              {porcentajeHoyPlanta || 0}%
            </Text>
            <Text fontSize="14px" alignSelf="end" pb={2}>
              Hoy
            </Text>
          </HStack>
          <Box bg="white" borderRadius={5}>
            <Progress
              borderRadius={5}
              colorScheme="orange.300"
              bgGradient="linear(to-r, orange.400, white)"
              size="md"
              max={100}
              min={0}
              value={80}
            />
          </Box>
          <HStack px={5} justifyContent="space-around">
            <HStack>
              <Text as="b" fontSize={30}>
                {reportesPlantaAvg.reduce((total, objeto) => {
                  const valorPropiedad = objeto["cargaTotal"];
                  return valorPropiedad !== null
                    ? total + parseFloat(valorPropiedad)
                    : total;
                }, 0)}
              </Text>
              <Text fontSize="14px" spacing={1} placeSelf={"end"}>
                Tns. Totales
              </Text>
            </HStack>

            <HStack>
              <Text as="b" fontSize={30}>
                {handlePorcentajeArena()}%
              </Text>
              <Text fontSize="14px" spacing={1} placeSelf={"end"}>
                Promedio
              </Text>
            </HStack>
          </HStack>

          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Target</Th>
                  <Th>Hoy</Th>
                  <Th>Avg.</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Tns. transportadas</Td>
                  <Td>{seleccionada[0].targetTnsDiaPlanta}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {tonsTransportadas}
                        {tonsTransportadas >
                        seleccionada[0].targetTnsDiaPlanta ? (
                          <StatArrow type={"increase"} color={"green.500"} />
                        ) : (
                          <StatArrow type="decrease" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{tonsTransportadasAvg.toFixed(1)}</Td>
                </Tr>
                <Tr>
                  <Td>
                    Standby en Planta
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td>{seleccionada[0].targetStandBy}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {standByPlantaHoy.toFixed(2)}
                        {standByPlantaHoy < seleccionada[0].targetStandBy ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{standByPlantaAvg.toFixed(0)}</Td>
                </Tr>
                <Tr>
                  <Td textAlign="left">
                    TT Pozo-Planta
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td>{seleccionada[0].targetIdaPozoPlanta}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {ttPozoPlantaHoy.toFixed(2)}
                        {ttPozoPlantaHoy <
                        seleccionada[0].targetIdaPozoPlanta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{ttPozoPlantaAvg.toFixed(0)}</Td>
                </Tr>
                <Tr>
                  <Td>
                    TT Planta-Pozo
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td>{seleccionada[0].targetVueltaPozoPlanta}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {ttPlantaPozoHoy.toFixed(2)}
                        {ttPlantaPozoHoy <
                        seleccionada[0].targetVueltaPozoPlanta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{ttPlantaPozoAvg.toFixed(0)}</Td>
                </Tr>
                <Tr>
                  <Td>
                    Vuelta Completa
                    <Text fontSize={8} color="gray">
                      (mins)
                    </Text>
                  </Td>
                  <Td>{seleccionada[0].targetVueltaCompleta}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {vueltaCompletaHoy.toFixed(2)}
                        {vueltaCompletaHoy <
                        seleccionada[0].targetVueltaCompleta ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{vueltaCompletaAvg.toFixed(0)}</Td>
                </Tr>
                <Tr>
                  <Td>VCD Total</Td>
                  <Td>{VDCFijada.toFixed(2)}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {VDCHoy.toFixed(2)}
                        {VDCHoy < VDCFijada ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{VDCAvg.toFixed(3)}</Td>
                </Tr>
                <Tr>
                  <Td>Avg. Kms/h</Td>
                  <Td>{plantaSeleccionada[0] && sumarKilometros("target")}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {plantaSeleccionada[0] && sumarKilometros("hoy")}
                        {plantaSeleccionada[0] &&
                        sumarKilometros("hoy") < sumarKilometros("target") ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{plantaSeleccionada[0] && sumarKilometros("total")}</Td>
                </Tr>
                <Tr>
                  <Td>Indice Logístico</Td>
                  <Td>{1}</Td>
                  <Td>
                    <Stat>
                      <StatNumber fontSize={14}>
                        {(VDCHoy / VDCFijada).toFixed(2)}
                        {VDCHoy / VDCFijada < 1 ? (
                          <StatArrow type={"decrease"} color={"green.500"} />
                        ) : (
                          <StatArrow type="increase" color={"red.500"} />
                        )}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>{(VDCAvg / VDCFijada).toFixed(2)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      );
    }
  };
  const handlePorcentajeArena = () => {
    let seleccionada;

    let x = reportes
      .filter((e) => e.statusRV === "Finalizado")
      .reduce(
        (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
        0
      );

    if (operacionSeleccionada) {
      seleccionada = operacion.detallePlantas.filter(
        (e) => e.idPlanta === parseInt(operacionSeleccionada)
      );
    }

    let y = reportes.filter(
      (elem) => elem.IdPlanta === seleccionada[0].idPlanta
    );
    y = y
      .filter((e) => e.statusRV === "Finalizado")
      .reduce(
        (total, objeto) => total + parseFloat(objeto["cargaTotal"] || 0),
        0
      );

    return ((y * 100) / x).toFixed(2) || 0;
  };
  const handleProximosArribos = () => {
    const reportesTP = reportes
      .filter((e) => e.status2RV === "EN TRÁNSITO A POZO")
      .filter((e) => e.statusRV === "En curso");
    return reportesTP.map((camion) => (
      <Tr
        key={camion.idCamion}
        textColor={
          moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
            ? "brand.naranja"
            : "brand.gris_primario"
        }
      >
        <Td>
          <Icon
            color={
              moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
                ? "brand.naranja"
                : "brand.verde"
            }
            as={
              moment.utc(camion.eta).format("YYYY-MM-DDTHH:mm:ss") < today
                ? RiErrorWarningFill
                : RiCheckboxCircleFill
            }
            fontSize="20px"
          />
        </Td>
        <Td>
          {camion.dominio && camion.nombreCamion
            ? `${camion.nombreCamion} -`
            : `${camion.nombreCamion}`}{" "}
          {camion.camionRV}
        </Td>
        <Td>
          {camion.eta ? moment.utc(camion.eta).format("DD-MM-YY HH:mm") : "--"}
        </Td>
      </Tr>
    ));
  };
  const handleStandByPozo = (e) => {
    let now = moment();

    if (e === "hoy") {
      let reportesHoy = reportes
        .filter((e) => e.statusRV === "Finalizado")
        .filter(
          (e) =>
            moment.utc(e.fechaRV).format("DD-MM-YYYY") ===
            moment.utc(now).format("DD-MM-YYYY")
        );

      const sumaStandByPozo = reportesHoy.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPozo;
      }, 0);

      if (!isNaN(sumaStandByPozo / reportesHoy.length)) {
        return (sumaStandByPozo / reportesHoy.length).toFixed(1);
      } else {
        return 0;
      }
    }
    if (e === "total") {
      let reportesTotal = reportes;

      const sumaStandByPozo = reportesTotal.reduce((acumulador, elemento) => {
        return acumulador + elemento.standByPozo;
      }, 0);

      if (!isNaN(sumaStandByPozo / reportesTotal.length)) {
        return (sumaStandByPozo / reportesTotal.length).toFixed(1);
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" bg="brand.fondos_secundarios" px={2}>
      {!loading && (
        <>
          <HStack w="100%">
            <Box>
              <IconButton
                icon={<MdArrowBack />}
                variant="link"
                onClick={() => navigate(-1)}
              />
            </Box>
            <Box>
              <Heading
                color="brand.azul_text"
                textAlign="left"
                fontWeight="semibold"
                size={["sm", "sm", "md", "md", "lg"]}
              >
                Detalle {operacion.idPadlocacion}
              </Heading>
              <Text fontSize="16px">
                Este es el resumen de las estadísticas de la operación
              </Text>
            </Box>
          </HStack>
          <VStack
            w="100%"
            bg="brand.fondos_primarios"
            borderRadius={10}
            overflowY="auto"
          >
            <HStack w="100%" px={5} justifyContent="space-around">
              <HStack>
                <Text>
                  Promedio etapas/día:{" "}
                  {operacion.etapasSC
                    ? handlePromedio(operacion.etapasSC[0]?.fechaInicioEtapaSC)
                    : "--"}{" "}
                </Text>
                <Text textColor="gray" fontSize="12px">
                  (Ayer: {handleEtapasAyer()} Hoy: {handleEtapasHoy()} Max:{" "}
                  {handleEtapasMax()})
                </Text>
              </HStack>
              <HStack>
                <Text>
                  Días operados:{" "}
                  {operacion.etapasSC
                    ? handleDiasOperados(
                        operacion.etapasSC[0]?.fechaInicioEtapaSC
                      )
                    : "--"}
                </Text>
                <Text textColor="gray" fontSize="12px" textAlign={"end"}>
                  (Días restantes{" "}
                  {operacion.etapasSC
                    ? handleDiasRestantes(
                        operacion.etapasTotalPad,
                        operacion.etapasSC[0]?.fechaInicioEtapaSC
                      )
                    : "--"}
                  aprox)
                </Text>
              </HStack>
            </HStack>
            <Divider h="1px" bg="brand.gris_primario" />
            <HStack w="100%">
              <Stack w={"34%"} h={"100%"} px={2}>
                <Stack w="100%" justifyContent="space-around" py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Etapas
                  </Heading>
                  <HStack>
                    <HStack
                      bg="brand.azul_text"
                      textAlign="left"
                      borderRadius={5}
                      p={3}
                    >
                      <Text
                        placeSelf="start"
                        fontWeight="bold"
                        fontSize={28}
                        textColor="white"
                      >
                        {operacion.etapasSC?.filter(
                          (e) => e.eliminado === false
                        ).length || 0}
                      </Text>
                      <Text textColor="white" fontSize="14px">
                        Completadas en total
                      </Text>
                    </HStack>
                    <HStack
                      bg="brand.azul_text"
                      textAlign="left"
                      borderRadius={5}
                      p={3}
                    >
                      <Text
                        placeSelf="start"
                        fontWeight="bold"
                        fontSize={28}
                        textColor="white"
                      >
                        {handleEtapasHoy()}
                      </Text>
                      <Text textColor="white" fontSize="14px">
                        Completadas hoy
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack w="100%" px={5}>
                    <HStack>
                      <Text fontSize="28px" fontWeight="bold">
                        {operacion.etapasSC
                          ? handlePorcetajeCompleto(
                              operacion.etapasSC || 0,
                              operacion.etapasTotalPad
                            ).toFixed(0)
                          : "0"}
                        {"%"}
                      </Text>
                      <Text fontSize="10px">
                        pad <br />
                        completo
                      </Text>
                    </HStack>
                    <Box bg="white" w="80%" borderRadius={5}>
                      <Progress
                        borderRadius={5}
                        colorScheme="orange"
                        size="md"
                        max={100}
                        min={0}
                        value={
                          operacion.etapasSC
                            ? handlePorcetajeCompleto(
                                operacion.etapasSC || 0,
                                operacion.etapasTotalPad
                              )
                            : 0
                        }
                        border={"1px solid #FF8200"}
                      />
                    </Box>
                  </HStack>
                </Stack>
                <Divider h="1px" bg="brand.gris_primario" />
                <Stack w="100%" justify={"space-between"} px={2} py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Sandcubes
                  </Heading>
                  <HStack justify={"space-between"}>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("llenosPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          style={{ "--icon-color": "#FF8200" }}
                          alignSelf="start"
                        >
                          <Sandcube />
                        </Box>

                        <Text fontSize="12px" alignSelf="start">
                          llenos <br /> en pozo
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("vaciosPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          alignSelf="start"
                          style={{ "--icon-color": "#505E70" }}
                        >
                          <Sandcube />
                        </Box>
                        <Text fontSize="12px" alignSelf="start">
                          vacíos <br /> en pozo
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack>
                      <Text placeSelf="start" fontWeight="bold" fontSize="32px">
                        {handleSandcubes("transitoPozo")}
                      </Text>
                      <VStack>
                        <Box
                          w={8}
                          h={8}
                          alignSelf="start"
                          style={{ "--icon-color": "#004B87" }}
                        >
                          <Sandcube />
                        </Box>
                        <Text fontSize="12px" alignSelf="start">
                          en tránsito <br /> a pozo
                        </Text>
                      </VStack>
                    </HStack>
                  </HStack>
                </Stack>
                <Divider h="1px" bg="brand.gris_primario" />
                <Stack w="100%" py={3}>
                  <Heading
                    fontSize={24}
                    placeSelf="start"
                    fontWeight="bold"
                    letterSpacing={1}
                  >
                    Camiones
                  </Heading>
                  <HStack spacing={1} justifyContent="space-between">
                    <Box>
                      <HStack>
                        <Text
                          fontSize="32px"
                          fontWeight="bold"
                          letterSpacing={1}
                        >
                          {handleUltimoViajeCamion()}
                        </Text>
                        <Icon as={MdOutlineAirportShuttle} fontSize="42px" />
                      </HStack>

                      <Text fontSize="12px">Camiones activos</Text>
                    </Box>
                    <Box>
                      <HStack spacing={1}>
                        <Text fontSize="1.5em" fontWeight="bold">
                          {handleStandByPozo("hoy")}
                        </Text>
                        <Icon as={MdOutlineTimer} fontSize="20px" />
                      </HStack>
                      <HStack fontSize="12px">
                        <Text>
                          Standby en <br />
                          pozo del día
                        </Text>
                        <Text color="gray" fontStyle="italic">
                          (mins)
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack spacing={1}>
                        <Text fontSize="1.5em" fontWeight="bold">
                          {handleStandByPozo("total")}
                        </Text>
                        <Icon as={MdOutlineTimer} fontSize="20px" />
                      </HStack>
                      <HStack fontSize="12px">
                        <Text>
                          Standby en <br /> pozo general
                        </Text>
                        <Text color="gray" fontStyle="italic">
                          (mins)
                        </Text>
                      </HStack>
                    </Box>
                  </HStack>
                </Stack>
                <Divider w="1px" bg="brand.gris_primario" />
                <HStack px={1}>
                  <Text as="b" fontSize="32px">
                    {handleTnsOperada(operacion)}
                  </Text>

                  <Text fontSize="12px" spacing={1}>
                    toneladas operadas
                  </Text>
                </HStack>
              </Stack>
              <Divider
                orientation="vertical"
                h="100%"
                w="1px"
                bg="brand.gris_primario"
              />
              <VStack w={"35%"} h={"100%"}>
                <Stack w={"100%"}>
                  <Select
                    fontSize={24}
                    textAlign="center"
                    fontWeight="bold"
                    letterSpacing={1}
                    variant="unstyled"
                    placeholder="Seleccione planta"
                    onChange={(e) => setOperacionSeleccionada(e.target.value)}
                  >
                    {operacion.detallePlantas.map((planta) => (
                      <option key={planta.idPlanta} value={planta.idPlanta}>
                        {planta.nombrePlanta}
                      </option>
                    ))}
                  </Select>
                </Stack>
                {handleDetallePlanta()}
              </VStack>
              <Divider
                orientation="vertical"
                h="100%"
                w="1px"
                bg="brand.gris_primario"
              />
              <VStack w={"31%"} pb={2}>
                <Heading
                  fontSize={24}
                  placeSelf="start"
                  fontWeight="bold"
                  letterSpacing={1}
                >
                  Próximos arribos
                </Heading>
                <Stack w={"100%"} h={"md"}>
                  <TableContainer overflowY="auto">
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>ID Camión</Th>
                          <Th>ETA</Th>
                        </Tr>
                      </Thead>
                      <Tbody overflowY="auto">{handleProximosArribos()}</Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              </VStack>
            </HStack>
            <Divider h="1px" bg="brand.gris_primario" />
            <Stack w="100%" h={"200px"}>
              <HStack
                w="100%"
                fontSize="14px"
                justifyContent="space-between"
                px={3}
              >
                <Text>Etapas completadas por dia</Text>
                <Badge textColor="brand.gris_primario" fontWeight="light">
                  <HStack>
                    <Icon as={MdInsertInvitation} color="gray" boxSize={5} />
                    <Text>{`${moment(operacion.fechaInicio).format(
                      "DD/MM/YYYY"
                    )} al ${moment().format("DD/MM/YYYY")}`}</Text>
                  </HStack>
                </Badge>
              </HStack>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={fusionarEtapasConCantidad()}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={"fechaII"} />
                  <YAxis minTickGap={10} />
                  <Tooltip />
                  <Bar dataKey="cantidad" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Stack>
          </VStack>
        </>
      )}
    </Stack>
  );
};

export default DetalleOperacion;
