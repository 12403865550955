import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  MdInsertInvitation,
  MdOutlineAirportShuttle,
  MdOutlineTimer,
} from "react-icons/md";
import {
  Heading,
  VStack,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Text,
  Divider,
  Icon,
  Badge,
  Stack,
  Box,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as Sandcube } from "../../../../assets/iconos/Sand.svg";
import moment from "moment";

const OperacionEnCurso = (props) => {
  const { operacion, x, y } = props;
  let llenosPozo = 0;
  let vaciosPozo = 0;
  let transitoPozo = 0;
  let today = moment();
  let todayII = moment();

  const fusionarEtapasConCantidad = () => {
    const fechasGeneradas = [];
    const cantidadesPorFecha = {};
    const fechaActual = moment();
    let fechaActualTemp = moment(operacion.fechaInicio);

    if (operacion.etapasSC) {
      operacion.etapasSC
        .filter((e) => e.eliminado === false)
        .forEach((etapa) => {
          const fechaFinalEtapaSC = moment(etapa.fechaFinalEtapaSC);

          if (fechaFinalEtapaSC.isSameOrAfter(fechaActualTemp, "day")) {
            const fechaFinalEtapaSCStr = fechaFinalEtapaSC.format("DD-MM");

            if (!cantidadesPorFecha[fechaFinalEtapaSCStr]) {
              cantidadesPorFecha[fechaFinalEtapaSCStr] = 0;
            }

            cantidadesPorFecha[fechaFinalEtapaSCStr] += 1;
          }
        });
    }
    while (fechaActualTemp.isSameOrBefore(fechaActual, "day")) {
      const diaMes = {
        dia: fechaActualTemp.date(),
        mes: fechaActualTemp.month() + 1,
        fecha: fechaActualTemp.toDate(),
      };

      const fecha = fechaActualTemp.format("DD-MM");
      const fechaII = fechaActualTemp.format("DD-MM");
      const cantidad = cantidadesPorFecha[fecha] || 0;

      fechasGeneradas.push({ ...diaMes, fechaII, cantidad });

      fechaActualTemp.add(1, "day");
    }

    return fechasGeneradas;
  };

  const handlePorcetajeCompleto = (completas, total) => {
    let etapas = completas.etapasSC || [];
    let porcentaje = (etapas.length / total) * 100;
    return porcentaje;
  };
  const handleEtapasHoy = () => {
    let b = moment(today).format("DD-MM-YYYY");

    let etapasHoy = operacion.etapasSC.filter(
      (element) => moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
    );

    return etapasHoy.length;
  };
  const handleCompletasDia = (element) => {
    let etapas = element?.etapasSC || [];
    let hoy = etapas.filter((e) => {
      return moment(e.fechaFinalEtapaSC).format("YYYY-MM-DD") === today;
    });

    return hoy.length;
  };
  const handleEtapasAyer = () => {
    let ayer = moment().subtract(1, "days");
    let b = moment(ayer).format("DD-MM-YYYY");

    let etapasAyer = operacion.etapasSC.filter(
      (element) => moment(element.fechaFinalEtapaSC).format("DD-MM-YYYY") === b
    );

    return etapasAyer.length;
  };
  const handleEtapasMax = () => {
    const fechaFinalCount = {};

    operacion.etapasSC.forEach((etapa) => {
      const fechaFinal = moment(etapa.fechaFinalEtapaSC).format("DD-MM-YYYY");

      if (fechaFinalCount[fechaFinal]) {
        fechaFinalCount[fechaFinal]++;
      } else {
        fechaFinalCount[fechaFinal] = 1;
      }
    });

    let cantidadMasComun = 0;

    for (const fechaFinal in fechaFinalCount) {
      if (fechaFinalCount[fechaFinal] > cantidadMasComun) {
        cantidadMasComun = fechaFinalCount[fechaFinal];
      }
    }

    return cantidadMasComun;
  };
  const handleTnsOperada = (elemento) => {
    let etapas = elemento.etapasSC;
    let totalSC;
    if (elemento.etapasSC) {
      totalSC = etapas
        .filter((e) => e.sandCubes)
        .reduce(
          (acc, cuerrentValue) => acc + cuerrentValue.sandCubes?.length,
          0
        );
    } else {
      return 0;
    }
    return totalSC * 13.5;
  };
  const handleSandcubes = (event) => {
    if (operacion.scAsociados && event === "llenosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          llenosPozo++;
        }
      });
      return llenosPozo;
    } else if (operacion.scAsociados && event === "vaciosPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN POZO" &&
          objeto.statusSandCube === "Vacio"
        ) {
          vaciosPozo++;
        }
      });
      return vaciosPozo;
    } else if (operacion.scAsociados && event === "transitoPozo") {
      operacion.scAsociados.forEach((objeto) => {
        if (
          objeto.ubicacionSandCube === "EN TRÁNSITO A POZO" &&
          objeto.statusSandCube === "Lleno"
        ) {
          transitoPozo++;
        }
      });
      return transitoPozo;
    } else {
      return 0;
    }
  };
  const handlePromedio = () => {
    let fecha = operacion.etapasSC
      ? operacion.etapasSC[0].fechaInicioEtapaSC
      : null;

    let f = moment(fecha);

    let promedio;
    if (fecha) {
      let diasOperados = todayII.diff(f, "days");
      if (diasOperados > 0) {
        promedio =
          (handleEtapasAyer() + handleEtapasHoy() + handleEtapasMax()) /
          diasOperados;
      } else {
        promedio = 1;
      }
    } else {
      promedio = 0;
    }

    return promedio.toFixed(2);
  };
  console.log(operacion);
  return (
    <Card
      w={["md", "xl", "lg", "lg", "2xl"]}
      h="100%"
      variant={"unstyled"}
      zIndex={10}
      bg={"white"}
    >
      <CardHeader>
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          fontWeight="semibold"
          color="brand.azul_text"
        >
          {operacion?.idPadlocacion}
        </Heading>
      </CardHeader>
      <Divider color="gray.300" size={"sm"} />
      <CardBody h="100%" w="100%">
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
          py={1}
        >
          Etapas
        </Heading>
        <HStack w="100%" justifyContent="space-between">
          <Text fontSize={["10px", "12px", "12px", "14px", "14px"]}>
            Etapas completadas por dia
          </Text>
          <Badge
            textColor="gray"
            fontWeight="light"
            fontSize={["10px", "12px", "12px", "12px", "14px"]}
          >
            <Icon
              as={MdInsertInvitation}
              color="gray"
              boxSize={4}
              alignSelf="end"
            />
            {moment(operacion?.fechaInicio).format("DD/MM/YYYY")} AL{" "}
            {moment(operacion?.fechaFinal).format("DD/MM/YYYY")}
          </Badge>
        </HStack>
        <HStack w="100%" justifyContent="space-around" pb={3}>
          <HStack
            w={["8rem", "12rem", "10rem", "10rem", "12rem"]}
            bg={"brand.azul_text"}
            flexDir="row"
            display="flex"
            textAlign="left"
            borderRadius={5}
            p={3}
          >
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["30px", "36px", "36px", "36px", "40px"]}
              textColor="white"
            >
              {operacion.etapasSC[operacion.etapasSC.length - 1]
                .numTotalEtapaSC || 0}
            </Text>

            <Text
              textColor="white"
              fontSize={["10px", "16px", "14px", "16px", "18px"]}
            >
              Completadas en total
            </Text>
          </HStack>
          <VStack w="100%">
            <Text fontSize={["10px", "12px", "12px", "14px", "16px"]}>
              {`Completas hoy: ${handleCompletasDia()}`}
            </Text>
            <Text
              fontSize={["10px", "12px", "12px", "14px", "16px"]}
            >{`Promedio: ${handlePromedio()}`}</Text>
          </VStack>
          <VStack w="100%">
            <HStack>
              <Text fontSize="28px" fontWeight="bold">
                {handlePorcetajeCompleto(
                  operacion,
                  operacion.etapasTotalPad
                ).toFixed(0)}
                %
              </Text>
              <Text fontSize="12px">
                pad <br />
                completo
              </Text>
            </HStack>
            <Box bg="white" w="80%" borderRadius={5}>
              <Progress
                borderRadius={5}
                colorScheme="orange"
                size="md"
                max={100}
                min={0}
                value={handlePorcetajeCompleto(
                  operacion,
                  operacion.etapasTotalPad
                )}
              />
            </Box>
          </VStack>
        </HStack>
        <Divider />
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
          py={1}
        >
          Sandcubes
        </Heading>
        <HStack w="100%" justifyContent="space-around">
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("llenosPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#FF8200" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              llenos <br /> en pozo
            </Text>
          </HStack>
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("vaciosPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#505E70" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              vacios <br /> en pozo
            </Text>
          </HStack>
          <HStack>
            <Text
              placeSelf="start"
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
            >
              {handleSandcubes("transitoPozo")}
            </Text>
            <Box w={8} h={8} alignSelf="start">
              <Sandcube stroke="#004B87" />
            </Box>
            <Text fontSize="12px" alignSelf="start">
              en transito <br /> a pozo
            </Text>
          </HStack>
        </HStack>
        <Divider />
        <Heading
          size={["sm", "md", "md", "md", "lg"]}
          placeSelf="start"
          fontWeight="bold"
        >
          Camiones
        </Heading>
        <HStack w="100%" justifyContent="space-around">
          <VStack>
            <HStack>
              <Text
                fontSize={["18px", "30px", "24px", "30px", "36px"]}
                fontWeight="bold"
                letterSpacing={1}
              >
                {y}
              </Text>
              <Icon
                as={MdOutlineAirportShuttle}
                fontSize={["18px", "18px", "20px", "24px", "30px"]}
              />
            </HStack>
            <Text fontSize={["10px", "12px", "12px", "12px", "14px"]}>
              Camiones activos
            </Text>
          </VStack>
          <Box>
            <HStack spacing={1}>
              <Text
                fontSize={["18px", "30px", "24px", "30px", "36px"]}
                fontWeight="bold"
                letterSpacing={1}
              >
                {x}
              </Text>
              <Icon
                as={MdOutlineTimer}
                fontSize={["18px", "18px", "20px", "24px", "30px"]}
              />
            </HStack>
            <HStack>
              <Text fontSize={["10px", "12px", "12px", "12px", "14px"]}>
                Standby en pozo <br /> del general
              </Text>
              <Text color="gray" fontSize="12px" fontStyle="italic">
                (mins)
              </Text>
            </HStack>
          </Box>

          <Divider orientation="vertical" />
          <VStack>
            <Text
              fontWeight="bold"
              fontSize={["18px", "30px", "24px", "30px", "36px"]}
              alignSelf="start"
            >
              {handleTnsOperada(operacion)}
            </Text>
            <Stack
              fontSize={["10px", "12px", "12px", "12px", "14px"]}
              spacing={1}
            >
              <Text>toneladas operadas</Text>
            </Stack>
          </VStack>
        </HStack>
        <HStack w={"100%"} h="100px">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={250}
              data={fusionarEtapasConCantidad()}
              margin={{
                left: -26,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"fechaII"} />
              <YAxis dataKey={"cantidad"} />
              <Tooltip />
              <Bar dataKey="cantidad" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default OperacionEnCurso;
