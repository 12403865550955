import React, { useEffect, useState } from "react";
import {
  Stack,
  HStack,
  Heading,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Button,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import {
  MdArrowRight,
  MdArrowLeft,
  MdNavigateBefore,
  MdFileDownloadDone,
} from "react-icons/md";
import {
  crearSandcubesPlanificacion,
  editarSandcubesPlanificacion,
  getSandcubes,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";

const QuintoPaso = (props) => {
  const {
    id,
    type,
    seleccionado,
    initPlanificacion,
    onClose,
    values,
    formValues,
    setActiveStep,
    activeStep,
  } = props;
  const [sandcubs, setSandcubs] = useState([]);
  const [filter, setFilter] = useState("");
  const [seleccionados, setSeleccionados] = useState(
    type === "editar" ? values.scAsociados : []
  );
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const initData = async () => {
    setLoading(true);
    let res = await getSandcubes();

    if (res.status === 200) {
      if (type === "editar") {
        let x = res.data.filter(
          (item1) =>
            !seleccionados.some(
              (item2) => item2.idSandCube === item1.idSandCube
            )
        );
        setSandcubs(x);
      } else {
        setSandcubs(res.data);
      }
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los SCs",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }

    if (type === "editar" && values.scAsociados) {
      setSeleccionados(values.scAsociados);
    }

    setLoading(false);
  };

  const handleSeleccionados = (sandcube) => {
    setSandcubs(
      sandcubs.filter((item) => item.idSandCube !== sandcube.idSandCube)
    );
    setSeleccionados([...seleccionados, sandcube]);
  };
  const handleLista = (e) => {
    setSeleccionados(
      seleccionados.filter((items) => items.idSandCube !== e.idSandCube)
    );
    setSandcubs([...sandcubs, e]);
  };

  const showSandcubes = () => {
    if (!filter) {
      return sandcubs;
    } else {
      let item = [];

      let filtrado = filter.toUpperCase();

      item = sandcubs.filter((element) => {
        return element.nombreSandCube.toString().includes(filtrado);
      });

      return item;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    let parsedSandcubes = seleccionados.map((e) => {
      if (type === "continuar") {
        return {
          idSandCube: e.idSandCube,
        };
      } else {
        return {
          idSandCube: e.idSandCube,
        };
      }
    });

    let res;
    if (type === "editar" || type === "reActivar") {
      res = await editarSandcubesPlanificacion({
        idPlanificacion: formValues.idPlanificacion,
        sandcubes: parsedSandcubes,
      });
    }
    if (type === "crear" || type === "continuar") {
      res = await crearSandcubesPlanificacion({
        idPlanificacion: formValues.idPlanificacion,
        sandcubes: parsedSandcubes,
      });
    }

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: `Planificación ${
          type === "crear"
            ? "creada"
            : type === "continuar"
            ? "creada"
            : "editada"
        } correctamente`,
        duration: 3000,
      });
      if (type === "continuar") {
        initData();
        initPlanificacion();
        onClose();
      } else {
        initData();
        navigate("/operaciones/planificacion");
      }
    } else {
      setLoading(false);
      return toast({
        status: "error",
        isClosable: true,
        title: `Error al ${
          type === "crear"
            ? "crear"
            : type === "continuar"
            ? "creada"
            : "editar"
        } planificación`,
        description: "Error al editar autoelevador",
      });
    }

    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="100%" justifyContent="space-between">
      {loading && loadingtable()}
      {!loading && sandcubs.length >= 1 && (
        <HStack w="100%" px={3}>
          <Stack w="50%">
            <HStack justifyContent="space-between" px={3}>
              <Heading size="md">Lista de Sandcubes</Heading>
              <Input
                w="xs"
                type="text"
                value={filter}
                placeholder={`Buscar`}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                variant="flushed"
              />
            </HStack>

            <TableContainer h="xs" overflowY="scroll">
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th>ID Sandcube</Th>
                    <Th>Malla</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {showSandcubes()
                    .filter((e) => e.condicion === "activo")
                    .map((sandcube) => (
                      <Tr key={sandcube.idSandCube}>
                        <Td>{sandcube.nombreSandCube}</Td>
                        <Td>{sandcube.malla}</Td>
                        <Td>
                          <IconButton
                            variant="link"
                            icon={<MdArrowRight />}
                            onClick={() => {
                              handleSeleccionados(sandcube);
                            }}
                            _hover={{ color: "brand.naranja" }}
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack w="50%">
            <Heading size="md">Seleccionados</Heading>
            <TableContainer h="xs" overflowY="scroll">
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>ID Sandcube</Th>
                    <Th>Malla</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {seleccionados.map((sandcube) => (
                    <Tr key={sandcube.idSandCube}>
                      <Td>
                        <IconButton
                          variant="link"
                          icon={<MdArrowLeft />}
                          onClick={() => {
                            handleLista(sandcube);
                          }}
                          _hover={{ color: "brand.naranja" }}
                        />
                      </Td>
                      <Td>{sandcube.nombreSandCube}</Td>
                      <Td>{sandcube.malla}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </HStack>
      )}
      {!loading && sandcubs.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
      <HStack w="100%" justifyContent="space-between" p={3}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          variant="outline"
          onClick={() => setActiveStep(activeStep - 1)}
          isDisabled={loading}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <Button
          color="brand.naranja"
          fontSize={12}
          letterSpacing={1}
          border="1px solid orange"
          variant="outline"
          leftIcon={<MdFileDownloadDone fontSize="20px" />}
          textColor="brand.naranja"
          form="FormularioPlanificacion"
          onClick={() => handleSubmit(values)}
          isDisabled={loading}
          _hover={{ bg: "white", color: "brand.naranja" }}
        >
          Finalizar
        </Button>
      </HStack>
    </Stack>
  );
};

export default QuintoPaso;
