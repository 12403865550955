import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  Box,
  IconButton,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Select,
  useSteps,
  InputGroup,
  InputLeftElement,
  Checkbox,
} from "@chakra-ui/react";
import {
  MdArrowBack,
  MdFileDownloadDone,
  MdAddCircleOutline,
  MdNavigateBefore,
  MdOutlineAdd,
  MdOutlineAttachMoney,
  MdOutlineRemove,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import {
  getTransportista,
  editarTransportista,
  crearTransportista,
  crearCamiones,
} from "../../helpers/api.helper";

const FormCrearEditarTransportista = (props) => {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [transportista, setTransportista] = useState([]);
  const [temp, setTemp] = useState([]);
  const [formValues, setFormValues] = useState({
    initialValues:
      type === "editar"
        ? {
            nombreEmpresaT: transportista.nombreEmpresaT,
            cuitCuilEmpresaT: transportista.cuitCuilEmpresaT,
            razonSocialEmpresaT: transportista.razonSocialEmpresaT,
            ciudadEmpresaT: transportista.ciudadEmpresaT,
            direccionEmpresaT: transportista.direccionEmpresaT,
            provinciaEmpresaT: transportista.provinciaEmpresaT,
            telefonoEmpresaT: transportista.telefonoEmpresaT,
            emaillEmpresaT: transportista.emaillEmpresaT,
            costoADemanda12: transportista.costoADemanda12,
            costoCompleto: transportista.costoCompleto,
            costoPenalizado: transportista.costoPenalizado,
            tipoContrato: transportista.tipoContrato,
            tipoContratoB: transportista.tipoContratoB,
            costoCompletoB: transportista.costoCompletoB,
            costoPenalizadoB: transportista.costoPenalizadoB,
            costoADemanda12B: transportista.costoADemanda12B,
          }
        : {
            nombreEmpresaT: "",
            cuitCuilEmpresaT: "",
            razonSocialEmpresaT: "",
            ciudadEmpresaT: "",
            direccionEmpresaT: "",
            provinciaEmpresaT: "",
            telefonoEmpresaT: "",
            emaillEmpresaT: "",
            costoADemanda12: "",
            costoCompleto: "",
            costoPenalizado: "",
            tipoContrato: "",
            tipoContratoB: "",
            costoCompletoB: "",
            costoPenalizadoB: "",
            costoADemanda12B: "",
          },
  });
  const [cantidadInputs, setCantidadInputs] = useState();
  const [showTipoContratoB, setShowTipoContratoB] = useState(false);
  const [nombre, setNombre] = useState();
  const steps = [
    { title: "Empresa", description: "Información" },
    { title: "Camiones", description: "Agregar camiones" },
  ];
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const initData = async () => {
    setLoading(true);
    let res = await getTransportista(id);

    if (res.status === 200) {
      if (type === "editar") {
        setFormValues({
          initialValues: {
            nombreEmpresaT: res.data[0].nombreEmpresaT,
            cuitCuilEmpresaT: res.data[0].cuitCuilEmpresaT,
            razonSocialEmpresaT: res.data[0].razonSocialEmpresaT,
            ciudadEmpresaT: res.data[0].ciudadEmpresaT,
            direccionEmpresaT: res.data[0].direccionEmpresaT,
            provinciaEmpresaT: res.data[0].provinciaEmpresaT,
            telefonoEmpresaT: res.data[0].telefonoEmpresaT,
            emaillEmpresaT: res.data[0].emaillEmpresaT,
            costoADemanda12: res.data[0].costoADemanda12,
            costoCompleto: res.data[0].costoCompleto,
            costoPenalizado: res.data[0].costoPenalizado,
            tipoContrato: res.data[0].tipoContrato,
            tipoContratoB: res.data[0].tipoContratoB,
            costoCompletoB: res.data[0].costoCompletoB,
            costoPenalizadoB: res.data[0].costoPenalizadoB,
            costoADemanda12B: res.data[0].costoADemanda12B,
          },
        });
      }
      setTransportista(res.data[0]);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  const handleNombreEmpresa = (e, setFieldValue) => {
    setNombre(e);
    setFieldValue("nombreEmpresaT", e);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%">
      <HStack px={5}>
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <HStack w="100%" justifyContent="space-between" pr={5} pb={5}>
          <HStack>
            <Heading
              fontWeight="medium"
              size="xl"
              fontFamily="fonts.title"
              color="brand.gris_primario"
            >
              {type === "editar" ? "Editar" : "Nueva"} empresa transportista
            </Heading>
          </HStack>
        </HStack>
      </HStack>
      {type === "crear" && (
        <Stepper w={"lg"} colorScheme={"orange"} index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      )}

      {!loading && activeStep === 0 && (
        <Formik
          initialValues={formValues.initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.nombreEmpresaT) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre de la empresa requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.cuitCuilEmpresaT) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "CUIT/CUIL requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarTransportista({
                nombreEmpresaT: values.nombreEmpresaT,
                cuitCuilEmpresaT: values.cuitCuilEmpresaT,
                razonSocialEmpresaT: values.razonSocialEmpresaT,
                ciudadEmpresaT: values.ciudadEmpresaT,
                direccionEmpresaT: values.direccionEmpresaT,
                provinciaEmpresaT: values.provinciaEmpresaT,
                telefonoEmpresaT: values.telefonoEmpresaT,
                emaillEmpresaT: values.emaillEmpresaT,
                costoADemanda12: values.costoADemanda12 || 0,
                costoCompleto: values.costoCompleto || 0,
                costoPenalizado: values.costoPenalizado || 0,
                tipoContrato: values.tipoContrato,
                tipoContratoB: values.tipoContratoB
                  ? values.tipoContratoB
                  : "ninguno",
                costoCompletoB: values.costoCompletoB
                  ? values.costoCompletoB
                  : 0,
                costoPenalizadoB: values.costoPenalizadoB
                  ? values.costoPenalizadoB
                  : 0,
                costoADemanda12B: values.costoADemanda12B
                  ? values.costoADemanda12B
                  : 0,
                id: transportista.idEmpresaT,
              });
            }
            if (type === "crear") {
              res = await crearTransportista({
                nombreEmpresaT: values.nombreEmpresaT,
                cuitCuilEmpresaT: values.cuitCuilEmpresaT,
                razonSocialEmpresaT: values.razonSocialEmpresaT,
                ciudadEmpresaT: values.ciudadEmpresaT,
                direccionEmpresaT: values.direccionEmpresaT,
                provinciaEmpresaT: values.provinciaEmpresaT,
                telefonoEmpresaT: values.telefonoEmpresaT,
                emaillEmpresaT: values.emaillEmpresaT,
                costoADemanda12: values.costoADemanda12 || 0,
                costoCompleto: values.costoCompleto || 0,
                costoPenalizado: values.costoPenalizado || 0,
                tipoContrato: values.tipoContrato,
                tipoContratoB: values.tipoContratoB,
                costoCompletoB: values.costoCompletoB || 0,
                costoPenalizadoB: values.costoPenalizadoB || 0,
                costoADemanda12B: values.costoADemanda12B || 0,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Transportista ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } transportista`,
                description:
                  res.data.error.message || "Error al editar transportista",
              });
            }

            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form id="formTransportista">
              <Stack px={3} spacing={3} w="100%">
                <HStack>
                  <FormControl w={"xs"} isRequired>
                    <FormLabel>Empresa</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Nombre de la empresa"
                      type="text"
                      name="nombreEmpresaT"
                      onChange={(e) =>
                        handleNombreEmpresa(e.target.value, setFieldValue)
                      }
                      onBlur={handleBlur}
                      value={values.nombreEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"} isRequired>
                    <FormLabel>CUIT/CUIL</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="CUIT/CUIL"
                      type="text"
                      name="cuitCuilEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cuitCuilEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel>Razón social</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Razón social"
                      type="text"
                      name="razonSocialEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.razonSocialEmpresaT}
                    />
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl w={"xs"}>
                    <FormLabel>Teléfono</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Nro. Teléfono"
                      type={"tel"}
                      name="telefonoEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telefonoEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Email"
                      type="text"
                      name="emaillEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emaillEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"}></FormControl>
                </HStack>
                <HStack>
                  <FormControl w={"xs"}>
                    <FormLabel>Calle</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Dirección"
                      type="text"
                      name="direccionEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.direccionEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel>Cuidad</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Cuidad"
                      type="text"
                      name="ciudadEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ciudadEmpresaT}
                    />
                  </FormControl>
                  <FormControl w={"xs"}>
                    <FormLabel>Provincia</FormLabel>
                    <Input
                      w={"xs"}
                      placeholder="Provincia"
                      type="text"
                      name="provinciaEmpresaT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.provinciaEmpresaT}
                    />
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl w={"xs"}>
                    <FormLabel>Tipo de contrato</FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="- Selecciona un tipo -"
                      type="text"
                      name="tipoContrato"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoContrato}
                    >
                      {values.tipoContratoB !== "MENSUAL" && (
                        <option value={"MENSUAL"}>Mensual</option>
                      )}
                      {values.tipoContratoB !== "A DEMANDA" && (
                        <option value={"A DEMANDA"}>A Demanda</option>
                      )}
                      {values.tipoContratoB !== "FLOTA PROPIA" &&
                        !showTipoContratoB && (
                          <option value={"FLOTA PROPIA"}>Flota Propia</option>
                        )}
                    </Select>
                  </FormControl>

                  {values.tipoContrato === "MENSUAL" && (
                    <>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Completo</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Mensual"
                            type={"number"}
                            name="costoCompleto"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoCompleto}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Penalizado</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Penalizado mensual"
                            type={"number"}
                            name="costoPenalizado"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoPenalizado}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Standby</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="StandBy mensual"
                            type={"number"}
                            name="costoStandBy"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoStandBy}
                          />
                        </InputGroup>
                      </FormControl>
                    </>
                  )}

                  {values.tipoContrato === "A DEMANDA" && (
                    <>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Completo</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="A demanda 24"
                            type={"number"}
                            name="costoCompleto"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoCompleto}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Penalizado</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Penalidad a demanda 24"
                            type={"number"}
                            name="costoPenalizado"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoPenalizado}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo A demanda 12</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="A demanda 12"
                            type={"number"}
                            name="costoADemanda12"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoADemanda12}
                          />
                        </InputGroup>
                      </FormControl>
                    </>
                  )}

                  {values.tipoContrato === "FLOTA PROPIA" && (
                    <>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Completo</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Flota propia"
                            type={"number"}
                            name="costoCompleto"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoCompleto}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl w={"3xs"} alignSelf="start">
                        <FormLabel>Costo Penalizado</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineAttachMoney color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Penalizado Flota propia"
                            type={"number"}
                            name="costoPenalizado"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costoPenalizado}
                          />
                        </InputGroup>
                      </FormControl>
                    </>
                  )}
                </HStack>
                {showTipoContratoB && (
                  <HStack>
                    <FormControl w={"sm"}>
                      <FormLabel>Tipo de contrato</FormLabel>
                      <Select
                        w={"xs"}
                        placeholder="- Selecciona un tipo -"
                        type="text"
                        name="tipoContratoB"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tipoContratoB}
                      >
                        {values.tipoContrato !== "MENSUAL" && (
                          <option value={"MENSUAL"}>Mensual</option>
                        )}
                        {values.tipoContrato !== "A DEMANDA" && (
                          <option value={"A DEMANDA"}>A Demanda</option>
                        )}
                      </Select>
                    </FormControl>

                    {values.tipoContratoB === "MENSUAL" && (
                      <>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo Completo</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="Mensual"
                              type={"number"}
                              name="costoCompletoB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoCompletoB}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo Penalizado</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="Penalizado mensual"
                              type={"number"}
                              name="costoPenalizadoB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoPenalizadoB}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo Standby</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="StandBy mensual"
                              type={"number"}
                              name="costoStandByB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoStandByB}
                            />
                          </InputGroup>
                        </FormControl>
                      </>
                    )}

                    {values.tipoContratoB === "A DEMANDA" && (
                      <>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo Completo</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="A demanda 24"
                              type={"number"}
                              name="costoCompletoB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoCompletoB}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo Penalizado</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="Penalidad a demanda 24"
                              type={"number"}
                              name="costoPenalizadoB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoPenalizadoB}
                            />
                          </InputGroup>
                        </FormControl>
                        <FormControl w={"3xs"} alignSelf="start">
                          <FormLabel>Costo A demanda 12</FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineAttachMoney color="gray.300" />
                            </InputLeftElement>
                            <Input
                              placeholder="A demanda 12"
                              type={"number"}
                              name="costoADemanda12B"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.costoADemanda12B}
                            />
                          </InputGroup>
                        </FormControl>
                      </>
                    )}
                  </HStack>
                )}
                <HStack
                  w={"100%"}
                  justifyContent={
                    values.tipoContrato !== "FLOTA PROPIA"
                      ? "space-between"
                      : "end"
                  }
                >
                  {values.tipoContrato !== "FLOTA PROPIA" && (
                    <Button
                      color="brand.naranja"
                      fontSize="12px"
                      variant="link"
                      size="lg"
                      leftIcon={
                        showTipoContratoB === false ? (
                          <MdOutlineAdd size={18} color="#FF8200" />
                        ) : (
                          <MdOutlineRemove size={18} color="#FF8200" />
                        )
                      }
                      onClick={() => setShowTipoContratoB(!showTipoContratoB)}
                      textColor="black"
                    >
                      {showTipoContratoB === false ? "Agregar" : "Quitar"} Tipo
                      de contrato
                    </Button>
                  )}
                  <HStack>
                    {type === "crear" && (
                      <Button
                        size={"md"}
                        variant="outline"
                        border="1px solid #FF8200"
                        leftIcon={<MdAddCircleOutline fontSize="20px" />}
                        color="brand.naranja"
                        onClick={() => {
                          setTemp(values);
                          setActiveStep(activeStep + 1);
                        }}
                        _hover={{ bg: "brand.naranja", color: "white" }}
                      >
                        Agregar camiones
                      </Button>
                    )}
                    <Button
                      rightIcon={<MdFileDownloadDone fontSize="20px" />}
                      form="formTransportista"
                      onClick={handleSubmit}
                      isLoading={loading}
                      _hover={{ bg: "white", color: "brand.naranja" }}
                      bg={"brand.naranja"}
                      color={"white"}
                      size={"md"}
                    >
                      Finalizar
                    </Button>
                  </HStack>
                </HStack>
              </Stack>
            </Form>
          )}
        </Formik>
      )}

      {!loading && activeStep === 1 && (
        <Formik
          initialValues={{
            rowCount: cantidadInputs,
            camiones: [
              {
                nombreCamion: "",
                dominio: "",
                idRSV: "",
              },
            ],
          }}
          onSubmit={async (values) => {
            setLoading(true);
            let res;
            let response;

            res = await crearTransportista({
              nombreEmpresaT: temp.nombreEmpresaT,
              cuitCuilEmpresaT: temp.cuitCuilEmpresaT,
              razonSocialEmpresaT: temp.razonSocialEmpresaT,
              ciudadEmpresaT: temp.ciudadEmpresaT,
              direccionEmpresaT: temp.direccionEmpresaT,
              provinciaEmpresaT: temp.provinciaEmpresaT,
              telefonoEmpresaT: temp.telefonoEmpresaT,
              emaillEmpresaT: temp.emaillEmpresaT,
              costoADemanda12: temp.costoADemanda12 || 0,
              costoCompleto: temp.costoCompleto || 0,
              costoPenalizado: temp.costoPenalizado || 0,
              costoStandBy: temp.costoStandBy || 0,
              tipoContrato: temp.tipoContrato,
              tipoContratoB: temp.tipoContratoB,
              costoCompletoB: temp.costoCompletoB || 0,
              costoPenalizadoB: temp.costoPenalizadoB || 0,
              costoADemanda12B: temp.costoADemanda12B || 0,
            });

            if (res.status === 200) {
              response = await getTransportista({
                nombre: temp.nombreEmpresaT,
              });
            }
            let parserdCamiones = values.camiones.map((camion) => {
              let tempProv = [];

              if (camion.rsv) {
                tempProv.push({
                  idProveedor: 1,
                  activada: camion.rsv ? 1 : 0,
                });
              }
              if (camion.microtrack) {
                tempProv.push({
                  idProveedor: 2,
                  activada: camion.microtrack ? 1 : 0,
                });
              }
              if (camion.geotab) {
                tempProv.push({
                  idProveedor: 3,
                  activada: camion.geotab ? 1 : 0,
                });
              }

              return {
                nombreCamion: camion.nombreCamion,
                dominio: camion.dominio,
                tipoContratoCamion: camion.tipoContratoCamion,
                idEmpresaT: response.data[0].idEmpresaT,
                proveedores: tempProv,
              };
            });

            res = await crearCamiones({ camiones: parserdCamiones });

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: "Empresa y Camiones creados correctamente",
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: "Error al crear",
                description:
                  res.data.error.message || "Error al crear camiones",
              });
            }
            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form id="formAgregarCamiones">
              <HStack w={"100%"} justifyContent={"space-between"} px={3}>
                <FormControl w={"xs"}>
                  <FormLabel>Cantidad</FormLabel>
                  <Input
                    name="rowCount"
                    type="number"
                    value={values.rowCount || 1}
                    onChange={(e) => {
                      const rowCount = parseInt(e.target.value) || 1;

                      const existingCamiones = values.camiones || [];
                      const newCamiones = [...existingCamiones];

                      if (rowCount < existingCamiones.length) {
                        newCamiones.splice(rowCount);
                      } else {
                        for (
                          let i = existingCamiones.length;
                          i < rowCount;
                          i++
                        ) {
                          newCamiones.push({
                            camiones: {
                              idCamion: "",
                              dominio: "",
                              tipoContratoCamion: "",
                              tipoContrato: "",
                            },
                            proveedores: {
                              proveedores: [false, false, false],
                              proveedorDef: "",
                            },
                          });
                        }
                      }
                      setFieldValue("rowCount", rowCount);
                      setFieldValue("camiones", newCamiones);
                    }}
                  />
                </FormControl>
                <HStack>
                  <Button
                    leftIcon={<MdNavigateBefore />}
                    color="brand.naranja"
                    bg="brand.fondos_secundarios"
                    border={"1px solid "}
                    isLoading={loading}
                    size="md"
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Atras
                  </Button>
                  <Button
                    placeSelf="end"
                    rightIcon={<MdFileDownloadDone />}
                    bg="brand.naranja"
                    form="formAgregarCamiones"
                    color="white"
                    isLoading={loading}
                    size="md"
                    onClick={handleSubmit}
                  >
                    Finalizar
                  </Button>
                </HStack>
              </HStack>
              <FieldArray name="camiones">
                {() => (
                  <HStack p={3}>
                    <FormControl>
                      <FormLabel>ID Interno</FormLabel>
                      <Stack>
                        {values.camiones.map((camion, index) => (
                          <Input
                            w={"150px"}
                            placeholder="ID Interno"
                            name={`camiones[${index}].nombreCamion`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.camiones[index].nombreCamion}
                          />
                        ))}
                      </Stack>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Dominio</FormLabel>
                      <Stack>
                        {values.camiones.map((camion, index) => (
                          <Input
                            w={"150px"}
                            placeholder="Dominio"
                            name={`camiones[${index}].dominio`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.camiones[index].dominio}
                          />
                        ))}
                      </Stack>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Tipo contrato</FormLabel>
                      <Stack>
                        {values.camiones.map((camion, index) => (
                          <Select
                            w={"3xs"}
                            placeholder="- Tipo contrato -"
                            name={`camiones[${index}].tipoContratoCamion`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.camiones[index].tipoContratoCamion}
                          >
                            <option>{temp.tipoContrato}</option>
                            {temp.tipoContratoB && (
                              <option>{temp.tipoContratoB}</option>
                            )}
                          </Select>
                        ))}
                      </Stack>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Proveedores</FormLabel>
                      <Stack>
                        {values.camiones.map((_, index) => (
                          <HStack py={2}>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].rsv}
                              name={`camiones[${index}].rsv`}
                            >
                              RSV
                            </Checkbox>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].microtrack}
                              name={`camiones[${index}].microtrack`}
                            >
                              Microtrack
                            </Checkbox>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].geotab}
                              name={`camiones[${index}].geotab`}
                            >
                              Geotab
                            </Checkbox>
                          </HStack>
                        ))}
                      </Stack>
                    </FormControl>
                  </HStack>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarTransportista;
