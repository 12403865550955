/* global google */
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Stack,
  HStack,
  Input,
  Select,
  Box,
  useToast,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  MdOutlineSave,
  MdAddCircleOutline,
  MdDriveFileRenameOutline,
  MdSearch,
} from "react-icons/md";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { Form, Formik } from "formik";
import { crearGeocercas } from "../helpers/api.helper";

const CrearGeocerca = (props) => {
  const { initData, geocercas, plantas, planificaciones } = props;
  const [loading, setLoading] = useState(false);

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({
    lat: -38.33595844666532,
    lng: -68.79461933603682,
  });
  const centerII = {
    lat: -38.33595844666532,
    lng: -68.79461933603682,
  };
  const [inCenter, setInCenter] = useState(false);
  const [points, setPoints] = useState([]);
  const toast = useToast();
  const mapRef = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: "#FF0000",
    fillOpacity: 0.5,
  };

  const handleMapClick = (e, setFieldValue) => {
    const { latLng } = e;
    const newLat = latLng.lat();
    const newLng = latLng.lng();

    let factor = 100000;
    let latitudEntera = Math.round(newLat * factor);
    let longitudEntera = Math.round(newLng * factor);
    let latitudDeNuevo = latitudEntera / factor;
    let longitudDeNuevo = longitudEntera / factor;
    if (!inCenter) {
      setCenter({ lat: newLat, lng: newLng });
      setInCenter(true);
      setFieldValue("latitudCentro", latitudDeNuevo);
      setFieldValue("longitudCentro", longitudDeNuevo);
    } else {
      const path = [...points, { lat: latitudDeNuevo, lng: longitudDeNuevo }];
      setPoints(path);
    }
  };

  const handleInfoWindow = (geocerca) => {
    let infowindow = new google.maps.InfoWindow({
      content: geocerca.nombreGeo,
      ariaLabel: "Uluru",
    });

    infowindow.setPosition({
      lat: geocerca.latitudCentro,
      lng: geocerca.longitudCentro,
    });
    infowindow.open(map);
  };
  const handleBuscar = (latitud, longitud) => {
    setCenter({ lat: latitud, lng: longitud });
  };

  const handleClean = () => {
    setPoints([]);
    setInCenter(false);
  };

  const handlePolygonComplete = () => {
    setPoints(false);
  };

  let initialValues = {
    nombreGeo: "",
    longitudCentro: "",
    latitudCentro: "",
    tipoGeo: "",
    radio: [],
    planificacion: "",
    idPlanta: "",
  };

  return (
    <>
      <Tooltip label="Agregar Geocerca">
        <Button
          size="sm"
          variant="outline"
          border="1px solid #FF8200"
          leftIcon={<MdAddCircleOutline fontSize="20px" />}
          color="brand.naranja"
          onClick={() => onOpen()}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Nueva geocerca
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva Geocerca</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                if (!values.nombreGeo) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Nombre de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.tipoGeo) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Tipo de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.latitudCentro) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Latitud de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.longitudCentro) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Longitud de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }

                if (!points[2]) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "El area de la geocerca debe estar definido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }

                let data =
                  !values.planificacion && values.idPlanta
                    ? {
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                        idPlanta: parseInt(values.idPlanta),
                        puntosGeo: points,
                      }
                    : !values.idPlanta && values.planificacion
                    ? {
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                        planificacion: values.planificacion,
                        puntosGeo: points,
                      }
                    : {
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                        puntosGeo: points,
                      };

                let res = await crearGeocercas(data);

                if (res.status === 200) {
                  toast({
                    status: "success",
                    isClosable: true,
                    title: "Geocerca creada correctamente",
                    duration: 3000,
                  });
                  resetForm();
                  setCenter(centerII);
                  initData();
                  onClose();
                } else {
                  return toast({
                    status: "error",
                    isClosable: true,
                    title: "Error al crear geocerca",
                    description:
                      res.data.error.message || "Error al crear geocerca",
                  });
                }
                setLoading(false);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <Stack w={"100%"} pt={1} pb={2}>
                    <Box w={"100%"} h={"sm"} bg={"black"}>
                      <GoogleMap
                        center={center}
                        zoom={17}
                        onClick={(e) => handleMapClick(e, setFieldValue)}
                        map={mapRef}
                        onLoad={(map) => setMap(map)}
                        mapTypeId={"satellite"}
                        mapContainerStyle={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {center && (
                          <Marker
                            map={map}
                            position={center}
                            draggable={true}
                            onDragEnd={(e) =>
                              setCenter({
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng(),
                              })
                            }
                          />
                        )}
                        {inCenter && (
                          <Polygon
                            paths={points}
                            options={options}
                            editable={true}
                            draggable={true}
                            onDragEnd={(e) => handlePolygonComplete(e)}
                          />
                        )}
                        {geocercas.map((geocerca) => (
                          <Polygon
                            key={geocerca.idGeocerca}
                            paths={geocerca.puntos}
                            options={options}
                            map={map}
                            onMouseOver={() => handleInfoWindow(geocerca)}
                          />
                        ))}
                      </GoogleMap>
                    </Box>
                    <HStack>
                      <Input
                        isRequired
                        size={"sm"}
                        placeholder={"Nombre"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"nombreGeo"}
                        value={values.nombreGeo}
                      />
                      <Select
                        size={"sm"}
                        placeholder={"- Seleccionar tipo -"}
                        isRequired
                        value={values.tipoGeo}
                        onChange={(e) => {
                          setFieldValue("tipoGeo", e.target.value);
                          if (e.target.value === "Planta") {
                            setFieldValue("planificacion", null);
                          } else if (e.target.value === "PAD") {
                            setFieldValue("idPlanta", null);
                          }
                        }}
                        onBlur={handleBlur}
                        name={"tipoGeo"}
                      >
                        <option value="Base">Base</option>
                        <option value="PAD">PAD</option>
                        <option value="Planta">Planta</option>
                        <option value="Ref">Punto de referencia</option>
                        <option value="baseTransportista">
                          Base Transportista
                        </option>
                        <option value="estacionServicio">
                          Estación Servicio
                        </option>
                        <option value="barrera">Barrera</option>
                        <option value="cercaniaPlanta">Cercanía planta</option>
                      </Select>
                      {values.tipoGeo === "PAD" && (
                        <Select
                          size={"sm"}
                          placeholder={"Seleccione Planificación"}
                          value={values.planificacion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"planificacion"}
                        >
                          {planificaciones.map((planificacion) => (
                            <option key={planificacion.idPlanificacion}>
                              {planificacion.idPadlocacion}
                            </option>
                          ))}
                        </Select>
                      )}
                      {values.tipoGeo === "Planta" && (
                        <Select
                          size={"sm"}
                          placeholder={"Seleccione Planta"}
                          value={values.idPlanta}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"idPlanta"}
                        >
                          {plantas
                            .filter((e) => !e.geocerca)
                            .map((planta) => (
                              <option
                                value={planta.idPlanta}
                                key={planta.idPlanta}
                              >
                                {planta.nombrePlanta}
                              </option>
                            ))}
                        </Select>
                      )}
                      <Input
                        size={"sm"}
                        placeholder={"Latitud"}
                        value={values.latitudCentro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={"number"}
                        name={"latitudCentro"}
                        isRequired
                      />
                      <Input
                        size={"sm"}
                        placeholder={"Longitud"}
                        value={values.longitudCentro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={"number"}
                        name={"longitudCentro"}
                        isRequired
                      />
                    </HStack>
                    <HStack justifyContent={"space-between"}>
                      <Tooltip label={"Borrar"}>
                        <IconButton
                          size="sm"
                          bg={"red"}
                          color={"white"}
                          icon={<MdDriveFileRenameOutline fontSize={"20px"} />}
                          onClick={handleClean}
                        />
                      </Tooltip>
                      <HStack>
                        <Tooltip label={"Buscar"}>
                          <IconButton
                            size="sm"
                            bg={"brand.naranja"}
                            color={"white"}
                            icon={<MdSearch fontSize={"20px"} />}
                            onClick={() =>
                              handleBuscar(
                                values.latitudCentro,
                                values.longitudCentro
                              )
                            }
                          />
                        </Tooltip>
                        <Button
                          size="sm"
                          bg={"brand.naranja"}
                          color={"white"}
                          leftIcon={<MdOutlineSave fontSize={"20px"} />}
                          onClick={handleSubmit}
                          isDisabled={loading}
                        >
                          Guardar
                        </Button>
                      </HStack>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CrearGeocerca;
