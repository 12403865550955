import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  IconButton,
  Text,
  Box,
  HStack,
  Stack,
  Heading,
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";
import { MdOutlineMarkunread, MdOutlineDrafts } from "react-icons/md";
import { putNotificacionesII } from "../../config/helpers/api.helper";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Bell } from "../../../assets/iconos/notifications_FILL0.svg";

const Notificationes = (props) => {
  const { notificaciones, setNotificaciones } = props;
  const navigate = useNavigate();

  const handleLeido = async (element) => {
    let data = {
      id: element.idnotificacionesBS,
      leidoNBS: !element.leidoNBS,
    };
    let res = await putNotificacionesII(data);

    if (res.status === 200) {
      const editar = notificaciones.map((item) => {
        if (item.idnotificacionesBS === element.idnotificacionesBS) {
          return {
            ...item,
            leidoNBS: !element.leidoNBS,
          };
        }
        return item;
      });

      setNotificaciones(editar);
    }
  };

  const openModal = async (item) => {
    if (item.nombreTipoE === "Registro Evento") {
      await putNotificacionesII({
        id: item.idnotificacionesBS,
        leidoNBS: true,
      });
      return navigate(`/operaciones/curso/eventos/${item.padLocacion}`);
    } else if (item.eventoNBS === "Creación Etapa") {
      await putNotificacionesII({
        id: item.idnotificacionesBS,
        leidoNBS: true,
      });
      return navigate(`/operaciones/curso/eventos/${item.padLocacion}`);
    } else {
      let data = {
        id: item.idnotificacionesBS,
        leidoNBS: !item.leidoNBS,
      };
      let res = await putNotificacionesII(data);

      if (res.status === 200) {
        const editar = notificaciones.map((e) => {
          if (e.idnotificacionesBS === item.idnotificacionesBS) {
            return {
              ...e,
              leidoNBS: !item.leidoNBS,
            };
          }
          return e;
        });

        setNotificaciones(editar);
      }
    }
  };

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Box position={"relative"} display={"inline-block"}>
          <IconButton bg="gray.300" borderRadius="50%" name="notificaciones">
            <Box>
              <Bell />
            </Box>
          </IconButton>
          {notificaciones.length > 0 && (
            <Box
              position="absolute"
              top="1px"
              right="-7px"
              bg="#B21515"
              color="white"
              borderRadius="50%"
              placeContent={"center"}
              width="20px"
              height="20px"
              display="flex"
              fontWeight={"light"}
              fontSize="12px"
              zIndex={10}
            >
              <Text>{notificaciones.length}</Text>
            </Box>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader letterSpacing={1}>
          Notificaciones {`(${notificaciones.length})`}
        </PopoverHeader>
        <PopoverBody>
          <Stack overflow={"auto"} maxH={"xs"}>
            {notificaciones.map((elem) => (
              <Card
                key={elem.idnotificacionesBS}
                size={"sm"}
                onClick={() => openModal(elem)}
                variant="unstyled"
                bg={
                  elem.leidoNBS === false ? "brand.fondos_secundarios" : "white"
                }
                p={2}
                cursor={"pointer"}
              >
                <CardHeader key={elem.idnotificacionesBS}>
                  <HStack justifyContent={"space-between"} borderRadius={5}>
                    <Heading
                      size={"md"}
                      fontWeight={"semibold"}
                      color={"gray.600"}
                      textAlign={"left"}
                    >
                      {elem.tituloNBS}
                    </Heading>
                    <IconButton
                      variant={"link"}
                      icon={
                        elem.leidoNBS === false ? (
                          <MdOutlineMarkunread
                            onClick={() => handleLeido(elem)}
                          />
                        ) : (
                          <MdOutlineDrafts />
                        )
                      }
                    />
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Heading size={"sm"} color={"gray"} fontWeight={"normal"}>
                    {elem.descripciónNBS}
                  </Heading>
                </CardBody>
              </Card>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Notificationes;
